import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  isEmailValid,
  sleep,
  toastifyConfiguration,
} from '../../components/util/helpers';
import { upsertWorkspaceMembers } from '../../services/workspace';
import { getUsers } from '../../services/organization';

class InviteWorkspaceMembersFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isSubmitting: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnUserInputChange = this.handleOnUserInputChange.bind(this);
    this.handleUsersFilterFetch = this.handleUsersFilterFetch.bind(this);
  }

  handleOnUserInputChange = input => {
    this.handleUsersFilterFetch(input);
  }

  handleUsersFilterFetch = async input => {
    event && event.preventDefault();
    const filteredUsers = await getUsers({ search: input, organization_id: this.props.organizationId });
    if (filteredUsers) {
      this.setState({ users: filteredUsers.entries });
    }
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const { organizationId, workspaceId, } = this.props;

    const { emails } = values;
    const userEmails = emails.map(email => email.value);
    const response = await upsertWorkspaceMembers({
      organization_id: organizationId,
      workspace_id: workspaceId,
      emails: userEmails,
    })

    // # TODO Add fetch workspace users (pass to here as a prop from OrganizationWorkspace)
    // # add if all users were added
    // # those that weren't, show flash
    if (response && !(response || {}).error) {
      location.reload();
    } else {
      toast.error(`Error adding workspace member(s). Please refresh and try again.`, toastifyConfiguration({}));
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const { isSubmitting, users } = this.state;

    return (
      <div className="invite-workspace-members-filter-form">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values);
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="emails"
                validate={mustBeValidEmail}
                component={ReactSelectAdapter}
                users={users}
                onInputChange={this.handleOnUserInputChange}
              />
              <div className="submit">
                {isSubmitting ? (
                  <div className="submit-button submitting w-100 add-member-input">
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Adding...</span>
                  </div>
                ) : (
                  <input type="submit" name="commit" value="Add Member" disabled={submitting || !Object.keys(values).length} />
                )}
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

InviteWorkspaceMembersFilterForm.propTypes = {
};

export default InviteWorkspaceMembersFilterForm;

// private

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select email-selector">
    <Select
      {...input}
      name="emails"
      isMulti
      isClearable
      components={components}
      isValidNewOption={isValidNewOption}
      formatCreateLabel={promptTextCreator}
      onInputChange={rest.onInputChange}
      filterOption={() => (true)}
      formatOptionLabel={formatUserOptionLabel}
      options={userOptions(rest.users)}
      // noOptionsMessage={() => null}
      placeholder="Add team members by typing their emails"
      styles={customStyles}
    />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
)

const components = {
  DropdownIndicator: null,
};

const isValidNewOption = value => isEmailValid(value);
const mustBeValidEmail = emails => emails && !!emails.filter(email => !isEmailValid(email.value)).length ? 'Must be a valid email': undefined;
const promptTextCreator = label => `Invite ${label}`;

const formatUserOptionLabel = ({ value, label, firstName, lastName }) => (
  <div className="user-option-label">
    <div>{label}</div>
  </div>
);

const userOptions = users =>
  users.map(obj =>
    ({ value: obj.email, label: obj.email, firstName: obj.first_name, lastName: obj.last_name })
  );

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '100px',
    overflowY: 'scroll',
  }),
};
