import { connect } from 'react-redux';
import ChannelList from './channel_list';
import {
  fetchChannels,
  fetchChannelsSummary,
  createChannel,
} from '../../../actions/channel_actions';
import { fetchUserChannels } from '../../../actions/user_channel_actions';
import {
  getJoinedChannels,
  isCurrentUserAccountAdmin,
  isFreeTrialAccount,
} from '../../../reducers/selectors';
import withRouter from '../../../util/with_router';

import { openModal } from '../../../actions/modal_actions';
import { isEducationOrganizationPersona } from '../../../reducers/selectors';

// same as connect
const msp = (state, ownProps) => {
  const { entities} = state;
  return {
    currentUser: entities.users[state.session.id],
    isFreeTrialAccount: isFreeTrialAccount({ entities }),
    channels: getJoinedChannels(state),
    channelsSummary: entities.channelsSummary,
    workspaceId: ownProps.match.params.workspaceId,
    workspaces: entities.workspaces,
    isCurrentUserAccountAdmin: isCurrentUserAccountAdmin({ entities }),
    location: ownProps.location,
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
    //TODO: Replace above with this
    // channels: Object.values(state.entities.channels),
  };
};

// same as dispatch
const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchChannels: () =>
      dispatch(fetchChannels({ organizationId, workspaceId })),
    createChannel: ({ channel }) =>
      dispatch(createChannel({ organizationId, workspaceId, channel })),
    // TODO: merge channels
    mergeChannels: (channelDrag, channelDrop) => dispatch(),
    fetchUserChannels:  () => dispatch(fetchUserChannels({ organizationId, workspaceId })),
    openModal: (modal, props) => dispatch(openModal(modal, props)),
    fetchChannelsSummary: () =>
      dispatch(fetchChannelsSummary({ organizationId, workspaceId })),
  };
};

export default withRouter(connect(msp, mdp)(ChannelList));
