import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddWorkspacePane from '../../../components/pane/AddWorkspacePane';
import { removeWorkspace, getWorkspaces, getWorkspacesCsv } from '../../../services/workspace';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import AboutPageDrawer from '../../AboutDrawers/WorkspacesSettingsPage/AboutPageDrawer';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.organizationWorkspaces.total_pages,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedWorkspace: {},
      search: '',
      workspaces: [], // props.organizationWorkspaces.entries,
      workspacesCount: 0, // props.organizationWorkspaces.total_count,
      isAboutPageDrawerOpen: false,
      isCsvActionInProgress: false,
    };
    this.handleOnClickAddWorkspace = this.handleOnClickAddWorkspace.bind(this);
    this.onAddWorkspacePaneClose = this.onAddWorkspacePaneClose.bind(this);
    this.handleOnClickEditWorkspace = this.handleOnClickEditWorkspace.bind(this);
    this.handleOnClickRemoveWorkspace = this.handleOnClickRemoveWorkspace.bind(this);
    this.handleWorkspacesSearch = this.handleWorkspacesSearch.bind(this);
    this.handleWorkspacesFilterFetch = this.handleWorkspacesFilterFetch.bind(this);
    this.handleGetPaginatedWorkspaces = this.handleGetPaginatedWorkspaces.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleWorkspacesFilterFetch(input), 500);

  handleWorkspacesSearch = event => {
    const input = event?.target?.value;
    if (!input) {
      return;
    }

    this.debounceSearch(input);
  }

  handleWorkspacesFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredWorkspaces = await this.props.fetchWorkspaces({ search: input });
    if (filteredWorkspaces) {
      this.setState({ workspaces: filteredWorkspaces.entries || [], search: input });
    }
  }

  handleGetPaginatedWorkspaces = async (page, pageSize) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchWorkspaces({
      page: page + 1,
      pageSize: pageSize,
      search,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddWorkspace = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedWorkspace: {} });
  }

  onAddWorkspacePaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditWorkspace = workspace => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedWorkspace: workspace });
  }

  handleConfirmDelete = async (workspace, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveWorkspace}
            subtitle="You want to delete this workspace?"
            itemToRemove={workspace}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveWorkspace = async (workspace, event) => {
    const { currentUser } = this.props;
    const response = await removeWorkspace({
      organization_id: currentUser?.organization_id,
      workspace_id: workspace.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedWorkspaces(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Workspace removed successfully.', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the workspace. Please try again.', toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getWorkspacesCsv({ organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    let {
      organizationWorkspaces,
      currentUser,
      workspaces,
      workspacesCount,
      pages,
      permittedRoles,
      currentLocale,
      // Note: Only enterprise accounts can add workspaces
      canAddWorkspaces,
    } = this.props;

    let {
      isAddMode,
      isCsvActionInProgress,
      isEditMode,
      selectedWorkspace,
    } = this.state;

    const columns = [{
      id: 'name',
      Header: 'Name',
      sortable: false,
      accessor: t => <a className='workspace-name' href={`/#/${currentLocale}/settings/workspaces/${t.id}`}>{t.name}</a>
    }, {
      id: 'created_at',
      Header: 'Created at',
      sortable: false,
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'user_count',
      Header: 'Member count',
      sortable: false,
      accessor: t => <span className='user-count'>{(t.user_count)}</span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: t => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditWorkspace.bind(this, t)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          {canAddWorkspaces && (
            <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, t)}>
              <i className="bi bi-trash3"></i>
            </span>
          )}
        </div>
      )
    }];

    return (
      <div className="settings organization-workspaces container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Workspaces</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>{pluralize('Total workspace', workspacesCount, false)}</div>
                <div className="count-container">{workspacesCount}</div>
              </div>
            </div>
            {canAddWorkspaces && (
              <div className="summary-actions">
                <div className="search">
                  <input
                    type="text"
                    className="form-control search-input"
                    onChange={this.handleWorkspacesSearch}
                    placeholder="Search workspaces" />
                </div>
                <div className="ms-2 button-actions">
                  <Button handleClick={this.handleOnClickAddWorkspace} label="Add New" classes="primary small add-new" />
                  {isCsvActionInProgress ? (
                    <button className="export small export-csv" type="button" disabled>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      <span>Exporting...</span>
                    </button>
                  ) : (
                    <Button
                      handleClick={() => {
                        this.setState({ isCsvActionInProgress: true });
                        this.handleCsvExport();
                      }}
                      label="Export CSV"
                      classes="export small export-csv"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={workspaces}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedWorkspaces(state.page, state.pageSize);
              }}
              getTableProps={() => {
                return {
                  style: { overflow: 'visible' }
                };
              }}
              getTbodyProps={() => {
                return {
                  style: { overflow: 'visible' }
                };
              }}
            />
            <SlidingPane
              className='add-workspace-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={isEditMode ? 'Edit Workspace' : 'Create New Workspace'}
              width='60%'
              subtitle=''
              onRequestClose={this.onAddWorkspacePaneClose}>
                <AddWorkspacePane
                  handleGetPaginatedWorkspaces={this.handleGetPaginatedWorkspaces}
                  currentUser={currentUser}
                  onAddWorkspacePaneClose={this.onAddWorkspacePaneClose}
                  isEditMode={isEditMode}
                  selectedWorkspace={selectedWorkspace}
                  permittedRoles={permittedRoles}
                />
            </SlidingPane>
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings teams-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
