import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Modal from 'react-modal';
// import SlidingPane from 'react-sliding-pane';
// import 'react-sliding-pane/dist/react-sliding-pane.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import EditTeamMemberPane from '../../../components/pane/EditTeamMemberPane';
import InviteTeamMembersFilterForm from '../../../components/pane/InviteTeamMembersFilterForm';
import { removeTeamMember, getTeamCsv } from '../../../services/team';
import {
  sleep,
  toastifyConfiguration,
} from '../../../components/util/helpers';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isPaneOpen: false,
      // isPaneOpenLeft: false,
      selectedTeamMember: {},
      team: props.organizationTeam,
      isCsvActionInProgress: false,
    };
    // this.handleOnClickEditTeamMember = this.handleOnClickEditTeamMember.bind(this);
    this.handleOnClickRemoveTeamMember = this.handleOnClickRemoveTeamMember.bind(this);
    // this.onEditTeamMemberPaneClose = this.onEditTeamMemberPaneClose.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmRemoval = this.handleConfirmRemoval.bind(this);
  }

  componentDidMount() {
    const { teamId } = this.props;
    if (teamId) {
      this.props.fetchTeam({ teamId });
    }
    Modal.setAppElement(this.el);
  }

  // handleOnClickEditTeamMember = user => {
  //   this.setState({ isPaneOpen: true, selectedTeamMember: user });
  // }

  handleConfirmRemoval = async (user, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveTeamMember}
            subtitle="You want to remove this team member?"
            confirmButtonText="Yes, remove it!"
            itemToRemove={user}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveTeamMember = async (user, event) => {
    event.preventDefault();
    const { currentUser, teamId } = this.props;

    const response = await removeTeamMember({
      organization_id: currentUser?.organization_id,
      team_id: teamId,
      team_member_id: user.id,
    });

    if (!(response || {}).error) {
      await this.props.fetchTeam({ teamId });
      toast.success('Team member removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the team member. Please try again.', toastifyConfiguration({}));
    }
  }

  // onEditTeamMemberPaneClose = () => {
  //   this.setState({ isPaneOpen: false });
  // }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser, team } = this.props;
    const { id, name } = team;

    await getTeamCsv({ id: id, team_name: name, organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  render() {
    const {
      currentUser,
      teamId,
      team,
      // permittedTeamRoles,
      currentLocale,
    } = this.props;

    const {
      isCsvActionInProgress,
      selectedTeamMember,
    } = this.state;

    const teamsUsersCount = team.users.length;

    const columns = [{
      id: 'user',
      Header: 'User',
      sortable: false,
      accessor: u => u.first_name
        ? (
          <div className='username'>
            {`${u.first_name} ${u.last_name} -- ${u.email}`}
          </div>
        ) : u.email
    }, {
      id: 'remove',
      Header: 'Action',
      sortable: false,
      accessor: u => (
        <div>
          {/*
            <span onClick={this.handleOnClickEditTeamMember.bind(this, u)} className='edit-cell'>
              <i className="bi bi-pencil-square"></i>
            </span>
          */}
          <span onClick={this.handleConfirmRemoval.bind(this, u)} className='remove-cell'>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }]

    return (
      <div className="settings organization-team container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Team</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="team-name">{`${team.name}:`}</div>
              <div className="count">
                <div>{pluralize('Members', teamsUsersCount, false)}</div>
                <div className="count-container">{teamsUsersCount}</div>
              </div>
            </div>
            <div className="summary-actions">
              <div className="team-member-search">
                <InviteTeamMembersFilterForm
                  organizationId={currentUser?.organization_id}
                  teamId={teamId}
                />
              </div>
              <div className="ms-2 button-actions">
                {isCsvActionInProgress ? (
                  <button className="export small export-csv" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Exporting...</span>
                  </button>
                ) : (
                  <Button
                    handleClick={() => {
                      this.setState({ isCsvActionInProgress: true });
                      this.handleCsvExport();
                    }}
                    label="Export CSV"
                    classes="export small export-csv"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={team.users}
              columns={columns}
            />
            {/*
              <SlidingPane
                className='add-team-pane'
                overlayClassName='sliding-pane-overlay'
                isOpen={ this.state.isPaneOpen }
                title={'Edit Team Member Access Level'}
                width='60%'
                subtitle=''
                onRequestClose={this.onEditTeamMemberPaneClose}>
                  <EditTeamMemberPane
                    teamId={teamId}
                    currentUser={currentUser}
                    onEditTeamMemberPaneClose={this.onEditTeamMemberPaneClose}
                    permittedTeamRoles={permittedTeamRoles}
                    selectedTeamMember={selectedTeamMember}
                  />
              </SlidingPane>
            */}
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
