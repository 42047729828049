import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  sleep,
  composeValidators,
  isEmailValid,
  toastifyConfiguration,
  areArraysEqualSets,
} from '../../components/util/helpers';
import { upsertWorkspace, updateWorkspace } from '../../services/workspace';

toast.configure();

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

class AddWorkspacePane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      onAddWorkspacePaneClose,
      currentUser,
      isEditMode,
      selectedWorkspace,
      handleGetPaginatedWorkspaces,
     } = this.props;
    const { name, description } = values;

    const workspace = isEditMode
      ? await updateWorkspace({
          organization_id: currentUser?.organization_id,
          workspace_id: selectedWorkspace.id,
          ...(description && { description }),
          ...(name && { name }),
        })
      : await upsertWorkspace({
          organization_id: currentUser?.organization_id,
          description,
          name,
        });

    if (workspace && !(workspace || {}).error) {
      onAddWorkspacePaneClose();
      await handleGetPaginatedWorkspaces(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      const action = isEditMode ? 'updated' : 'added';
      toast.success(`Workspace, ${workspace?.name}, ${action} successfully!`, toastifyConfiguration({}));
    } else {
      const action = isEditMode ? 'updating' : 'adding';
      toast.error(`Error ${action} workspace, ${name}. Please try again.`, toastifyConfiguration({}));
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const { isEditMode, selectedWorkspace, permittedRoles, workspaceTagOptions } = this.props;
    const workspaceTags = (selectedWorkspace.tags || []).map(tag => ({
      label: tag.name,
      value: tag.name,
    }));
    const { isSubmitting } = this.state;

    return (
      <div className="add-workspace-pane">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values);
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-fields-container">
                <div className="workspace-info-section">
                  <div className="fields">
                    <div className="field-container mb-3">
                      <label>Name</label>
                      <Field
                        name="name"
                        validate={required}
                        initialValue={isEditMode ? selectedWorkspace.name : undefined}
                      >
                        {({ input, meta }) => (
                          <p className="field name">
                            <input {...input} type="text" placeholder="Workspace name" />
                            {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                          </p>
                        )}
                      </Field>
                    </div>
                    <div className="field-container">
                      <label>Description (optional)</label>
                      <Field
                        name="description"
                        initialValue={isEditMode ? selectedWorkspace.description : undefined}
                      >
                        {({ input, meta }) => (
                          <p className="field description">
                            <textarea {...input} placeholder="Description of workspace" />
                            {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                          </p>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="submit">
                    {isSubmitting ? (
                      <div className="submit-button submitting d-flex align-items-center justify-content-center">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span>Submitting...</span>
                      </div>
                    ) : (
                      <input type="submit" name="commit" value="Submit" disabled={submitting} />
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

AddWorkspacePane.propTypes = {
  handleGetPaginatedWorkspaces: PropTypes.func,
};

export default AddWorkspacePane;

// private

const required = value => value ? undefined : 'Required';
