import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import moment from 'moment';
import 'react-table/react-table.css';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddUserPane from '../../../components/pane/AddUserPane';
import { removeUser, getUsers, getUsersCsv } from '../../../services/organization';
import { toast } from 'react-toastify';
import {
  permittedRolesList,
  sleep,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import AboutPageDrawer from '../../AboutDrawers/UsersSettingsPage/AboutPageDrawer';
import BulkAddUsersForm from './bulk_add_users_form'

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.organizationUsers.total_pages,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedUser: {},
      search: '',
      users: [], // props.organizationUsers.entries,
      usersCount: 0, // props.organizationUsers.total_count,
      isAboutPageDrawerOpen: false,
      isCsvActionInProgress: false,
    };
    this.handleOnClickAddUser = this.handleOnClickAddUser.bind(this);
    this.onAddUserPaneClose = this.onAddUserPaneClose.bind(this);
    this.handleOnClickEditUser = this.handleOnClickEditUser.bind(this);
    this.handleOnClickRemoveUser = this.handleOnClickRemoveUser.bind(this);
    this.handleUsersSearch = this.handleUsersSearch.bind(this);
    this.handleUsersFilterFetch = this.handleUsersFilterFetch.bind(this);
    this.handleGetPaginatedUsers = this.handleGetPaginatedUsers.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
    this.handleOpenBulkAddUsers = this.handleOpenBulkAddUsers.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleUsersFilterFetch(input), 500);

  handleUsersSearch = event => {
    const input = event?.target?.value;
    if (!input) {
      return;
    }

    this.debounceSearch(input);
  }

  handleUsersFilterFetch = async input => {
    const { currentUser } = this.props;
    const filteredUsers = await this.props.fetchUsers({ search: input });
    if (filteredUsers) {
      this.setState({ users: filteredUsers.entries || [], search: input });
    }
  }

  handleGetPaginatedUsers = async (page, pageSize) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchUsers({
      page: page + 1,
      pageSize: pageSize,
      search,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddUser = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedUser: {} });
  }

  onAddUserPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditUser = user => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedUser: user });
  }

  handleConfirmDelete = async (user, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveUser}
            subtitle="You want to delete this user?"
            itemToRemove={user}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOpenBulkAddUsers = async (event) => {
    const {
      canAddWorkspaces,
      currentUser,
      currentLocale,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      workspacesAvailable,
    } = this.props;

    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <BulkAddUsersForm
            canAddWorkspaces={canAddWorkspaces}
            organizationId={currentUser?.organization_id}
            currentUser={currentUser}
            currentLocale={currentLocale}
            isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
            isCurrentUserWorkspaceManager={isCurrentUserWorkspaceManager}
            onClose={onClose}
            handleGetPaginatedUsers={this.handleGetPaginatedUsers}
            defaultPage={DEFAULT_PAGE}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            workspacesAvailable={workspacesAvailable}
          />
        );
      }
    });
  }

  handleOnClickRemoveUser = async (user, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const response = await removeUser({
      organization_id: currentUser?.organization_id,
      user_id: user.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedUsers(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('User removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the user. Please try again.', toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getUsersCsv({ organization_id: currentUser?.organization_id });
    await sleep(300);
    this.setState({ isCsvActionInProgress: false });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {

    let {
      organizationUsers,
      currentLocale,
      currentUser,
      organizationTeams,
      users,
      usersCount,
      pages,
      workspacesAvailable,
      canAddWorkspaces,
      isBulkAddUsersEnabled,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
    } = this.props;

    let {
      isAddMode,
      isEditMode,
      isCsvActionInProgress,
      selectedUser,
    } = this.state;

    const columns = [{
      id: 'user',
      Header: 'User',
      sortable: false,
      accessor: u => u.first_name ? `${u.first_name} ${u.last_name}` : '',
    }, {
      accessor: 'email',
      Header: 'Email',
      sortable: false,
    }, {
      id: 'last_sign_in_at',
      Header: 'Last activity',
      sortable: false,
      accessor: u =>
        <span className='user-last-login'>{u.last_sign_in_at_was ? moment(u.last_sign_in_at_was).utc().format('YYYY-MM-DD, h:mm:ss a') : 'no activity'}</span> // Custom cell components!
    }, {
      id: 'created_at',
      Header: 'Created at',
      sortable: false,
      accessor: u =>
        <span className='user-created-at'>{moment(u.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'roles',
      Header: 'Roles',
      sortable: false,
      accessor: u => <span className='user-roles'>{u.roles.map(r => r.name).join(', ')}</span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: u => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditUser.bind(this, u)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, u)}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }]

    return (
      <div className="settings organization-users container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Access</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>{pluralize('Total User', usersCount, false)}</div>
                <div className="count-container">{usersCount}</div>
              </div>
            </div>
            <div className="summary-actions">
              <div className="search">
                <input
                  type="text"
                  className="form-control search-input"
                  onChange={this.handleUsersSearch}
                  placeholder="Search users" />
              </div>
              <div className="ms-2 button-actions">
                <Button
                  handleClick={this.handleOnClickAddUser}
                  label="Add New"
                  classes="primary small add-new"
                />
                {isCsvActionInProgress ? (
                  <button className="export small export-csv" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Exporting...</span>
                  </button>
                ) : (
                  <Button
                    handleClick={() => {
                      this.setState({ isCsvActionInProgress: true });
                      this.handleCsvExport();
                    }}
                    label="Export CSV"
                    classes="export small export-csv"
                  />
                )}
              </div>
              {isBulkAddUsersEnabled && (
                <div
                  className="bulk-add-action ms-2"
                  onClick={() => this.handleOpenBulkAddUsers()}
                >
                  Bulk Add
                </div>
              )}
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={users}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedUsers(state.page, state.pageSize);
                // this.handleGetPaginatedUsers(state.page, state.pageSize, state.sorted, state.filtered)
              }}
            />
            <SlidingPane
              className='add-user-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={isEditMode ? 'Edit User': 'Add New User'}
              hideHeader
              width='90%'
              subtitle=''
              onRequestClose={this.onAddUserPaneClose}>
                <AddUserPane
                  handleGetPaginatedUsers={this.handleGetPaginatedUsers}
                  currentUser={currentUser}
                  onAddUserPaneClose={this.onAddUserPaneClose}
                  organizationTeams={organizationTeams.entries}
                  permittedRoles={permittedRolesList}
                  isEditMode={isEditMode}
                  selectedUser={selectedUser}
                  canAddWorkspaces={canAddWorkspaces}
                  workspacesAvailable={workspacesAvailable}
                />
            </SlidingPane>
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings users-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
