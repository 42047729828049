import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddCustomerTagPane from '../../../components/pane/AddCustomerTagPane';
import { removeTag, updateTag, getCustomerTagsCsv } from '../../../services/organization_api';
import { toast } from 'react-toastify';
import {
  sleep,
  statusBadge,
  statusMapping,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import AboutPageDrawer from '../../AboutDrawers/CustomerTagsSettingsPage/AboutPageDrawer';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedTag: {},
      search: '',
      tags: [],
      tagsCount: 0,
      isAboutPageDrawerOpen: false,
      isCsvActionInProgress: false,
    };
    this.handleOnClickAddCustomerTag = this.handleOnClickAddCustomerTag.bind(this);
    this.onAddCustomerTagPaneClose = this.onAddCustomerTagPaneClose.bind(this);
    this.handleOnClickEditCustomerTag = this.handleOnClickEditCustomerTag.bind(this);
    this.handleOnClickRemoveCustomerTag = this.handleOnClickRemoveCustomerTag.bind(this);
    this.handleCustomerTagsSearch = this.handleCustomerTagsSearch.bind(this);
    this.handleCustomerTagsFilterFetch = this.handleCustomerTagsFilterFetch.bind(this);
    this.handleGetPaginatedCustomerTags = this.handleGetPaginatedCustomerTags.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleConfirmDisable = this.handleConfirmDisable.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
    this.handleOnDisableTag = this.handleOnDisableTag.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleCustomerTagsFilterFetch(input), 500);

  handleCustomerTagsSearch = event => {
    const input = event?.target?.value;
    if (!input) {
      return;
    }

    this.debounceSearch(input);
  }

  handleCustomerTagsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredCustomerTags = await this.props.fetchCustomerTags({ search: input });
    if (filteredCustomerTags) {
      this.setState({ tags: filteredCustomerTags.entries || [], search: input });
    }
  }

  handleGetPaginatedCustomerTags = async (page, pageSize) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchCustomerTags({
      page: page + 1,
      pageSize: pageSize,
      search,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddCustomerTag = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedTag: {} });
  }

  onAddCustomerTagPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditCustomerTag = tag => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedTag: tag });
  }

  handleConfirmDelete = async (tag, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveCustomerTag}
            subtitle="You want to delete this tag?"
            itemToRemove={tag}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmDisable = async (tag, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnDisableTag}
            subtitle="You want to disable this tag?"
            itemToRemove={tag}
            event={event}
            onClose={onClose}
            confirmButtonText={'Yes, disable it!'}
          />
        );
      }
    });
  }

  handleOnClickRemoveCustomerTag = async (tag, event) => {
    const { currentUser } = this.props;
    const response = await removeTag({
      organizationId: currentUser?.organization_id,
      tagId: tag.id,
    });
    if (!(response || {}).error && !(response || {}).dependency) {
      await this.handleGetPaginatedCustomerTags(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Tag removed successfully.', toastifyConfiguration({}));
    } else if ((response || {}).dependency) {
      toast.warning(`Cannot remove tags in use by resources. Please try disabling the tag instead.`, toastifyConfiguration({}));
    } else {
      toast.error('Error removing the tag. Please try again.', toastifyConfiguration({}));
    }
  }

  handleOnDisableTag = async (tag, event) => {
    const { currentUser } = this.props;
    const response = await updateTag({
      organizationId: currentUser?.organization_id,
      tagId: tag.id,
      disabled: true,
    });

    if (!(tag || {}).error) {
      await this.handleGetPaginatedCustomerTags(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success(`Tag, ${tag.name}, disabled successfully!`, toastifyConfiguration({}));
    } else {
      toast.error(`Error disabling tag ${tag.name}. Please try again.`, toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getCustomerTagsCsv({ organizationId: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    let {
      currentUser,
      tags,
      tagsCount,
      pages,
      currentLocale,
      // Note: Only enterprise accounts can add tags
      canAddWorkspaces,
    } = this.props;

    let {
      isAddMode,
      isCsvActionInProgress,
      isEditMode,
      selectedTag,
    } = this.state;

    const columns = [{
      accessor: 'name',
      Header: 'Customer tag name',
      sortable: false,
    }, {
      id: 'created_at',
      Header: 'Created at',
      sortable: false,
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'customer_health_status',
      Header: 'Customer health status',
      sortable: false,
      accessor: t => (
        <div className={`status pill pt-2 pb-2 badge ${statusBadge(t.customer_health_status)} ${handleNeutralStatusClass(t.customer_health_status)} d-flex align-items-center justify-content-center w-75`}>
          {statusMapping(t.customer_health_status) || 'null'}
        </div>
      )
    }, {
      id: 'updated_at',
      Header: 'Updated at',
      sortable: false,
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'customer_renewal_date',
      Header: 'Customer renewal date',
      sortable: false,
      accessor: t => <span>{t.customer_renewal_date ? moment(t.customer_renewal_date).utc().format('YYYY-MM-DD') : 'N/A'}</span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: t => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditCustomerTag.bind(this, t)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, t)}>
            <i className="bi bi-trash3"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDisable.bind(this, t)}>
            <i className="bi bi-sign-do-not-enter"></i>
          </span>
        </div>
      )
    }];

    return (
      <div className="settings organization-workspaces customer-tags container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Customer Tags</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>{pluralize('Total customer tag', tagsCount, false)}</div>
                <div className="count-container">{tagsCount}</div>
              </div>
            </div>
            <div className="summary-actions">
              <div className="search">
                <input
                  type="text"
                  className="form-control search-input"
                  onChange={this.handleCustomerTagsSearch}
                  placeholder="search tags" />
              </div>
              <div className="ms-2 button-actions">
                {canAddWorkspaces && (
                  <Button
                    handleClick={this.handleOnClickAddCustomerTag}
                    label="Add New"
                    classes="primary small add-new"
                  />
                )}
                {isCsvActionInProgress ? (
                  <button className="export small export-csv" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Exporting...</span>
                  </button>
                ) : (
                  <Button
                    handleClick={() => {
                      this.setState({ isCsvActionInProgress: true });
                      this.handleCsvExport();
                    }}
                    label="Export CSV"
                    classes="export small export-csv"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={tags}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedCustomerTags(state.page, state.pageSize);
              }}
              getTableProps={() => {
                return {
                  style: { overflow: 'visible' }
                };
              }}
              getTbodyProps={() => {
                return {
                  style: { overflow: 'visible' }
                };
              }}
            />
            <SlidingPane
              className='add-tag-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={isEditMode ? 'Edit Workspace' : 'Create New Workspace'}
              width='60%'
              subtitle=''
              onRequestClose={this.onAddCustomerTagPaneClose}>
                <AddCustomerTagPane
                  handleGetPaginatedCustomerTags={this.handleGetPaginatedCustomerTags}
                  currentUser={currentUser}
                  onAddCustomerTagPaneClose={this.onAddCustomerTagPaneClose}
                  isEditMode={isEditMode}
                  selectedTag={selectedTag}
                />
            </SlidingPane>
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings teams-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;

const handleNeutralStatusClass = status =>
  status === 'neutral' ? 'text-dark' : '';
