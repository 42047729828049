import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ConfirmCancelPlanModal from './confirm_cancel_plan_modal';
import ConfirmCancelEnterprisePlanModal from './confirm_cancel_enterprise_plan_modal';
import ConfirmSwapPlanModal from './confirm_swap_plan_modal';
import ConfirmChooseEnterprisePlanModal from './confirm_choose_enterprise_plan_modal';
import PlanIsConfirmedModal from './plan_is_confirmed_modal';
import PlanIsNotConfirmedModal from './plan_is_not_confirmed_modal';
import PlanUpgradeErrorModal from './plan_upgrade_error_modal';
import PlanCanceledSuccessfullyModal  from './plan_canceled_successfully_modal';

import {
  cancelSubscription,
  contactUs,
  contactUsForEnterprise,
  goToCheckout,
  handleSuccessfulPayment,
  swapSubscription,
} from '../../../services/payments_api';
import  { queryParamValue } from '../../util/helpers';

const FREE_TRIAL_PLAN_NAME = 'free_trial';
const PRO_PLAN_NAME = 'pro';
const BUSINESS_PLAN_NAME = 'business';
const ENTERPRISE_PLAN_NAME = 'enterprise';


const buttonClassName = (accountType, plan) =>
  accountType === plan
    ? 'select-plan active-plan btn btn-primary mt-3 mb-3'
    : 'select-plan btn btn-primary mt-3 mb-3';

const buttonLabel = (accountType, plan) =>
  accountType === plan ? 'Cancel' : 'GET STARTED';

const isMyPlan = (accountType, plan) =>
  accountType === plan ? true : false;

const doesUserHaveASwappablePlan = ({ accountType }) =>
  accountType === PRO_PLAN_NAME || accountType === BUSINESS_PLAN_NAME;

const FREE_TRIAL_PLAN_ITEMS = [
  '1 organization workspace',
  'Unlimited users',
  'Up to 100 customer requests',
  'Access to the most recent 90 days of message history',
  'Email notifications',
  'Up to 20 teams to represent the structure of your organization',
];

const PRO_PLAN_ITEMS = [
  'Up to 5,000 customer requests',
  'The full context of your organization’s message history at your fingertips',
  'User roles and permissions',
  'Email support',
];

const BUSINESS_PLAN_ITEMS = [
  'Up to 20,000 customer requests',
  'Advanced access controls with 2FA',
  'Compliance requirements met with data exports for all messages',
  'Audit logs up to 1 year',
  'Up to 200 teams',
  'Prioritized email support',
];

const ENTERPRISE_PLAN_ITEMS = [
  'Unlimited customer requests',
  'Unlimited organization workspaces',
  'Unlimited audit logs',
  'Unlimited teams',
  'Premium support',
];

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleMonthly: true,
    };
    this.handleOnChoosePlan = this.handleOnChoosePlan.bind(this);
    this.handleOnChooseEnterprisePlan = this.handleOnChooseEnterprisePlan.bind(this);
    this.handleOnCancelPlan = this.handleOnCancelPlan.bind(this);
    this.handleOnCancelEnterprisePlan = this.handleOnCancelEnterprisePlan.bind(this);
    this.handleConfirmCancelPlan = this.handleConfirmCancelPlan.bind(this);
    this.handleConfirmCancelEnterprisePlan = this.handleConfirmCancelEnterprisePlan.bind(this);
    this.handleConfirmChooseEnterprisePlan = this.handleConfirmChooseEnterprisePlan.bind(this);
    this.handlePlanConfirmation = this.handlePlanConfirmation.bind(this);
    this.handlePlanConfirmationError = this.handlePlanConfirmationError.bind(this);
    this.handleConfirmSwapPlan = this.handleConfirmSwapPlan.bind(this);
    this.handleOnSwapPlan = this.handleOnSwapPlan.bind(this);
    this.handleOnContactUs = this.handleOnContactUs.bind(this);
    this.handlePlanUpgradeError = this.handlePlanUpgradeError.bind(this);
    this.handlePlanCanceledSuccessfullyModal = this.handlePlanCanceledSuccessfullyModal.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    const { search } = this.props.location;
    const isSuccessfulPayment = queryParamValue({ search, key: 'plan_subscription_successful' });
    const isFailedPayment = queryParamValue({ search, key: 'plan_subscription_error' });

    if (isSuccessfulPayment) {
      const { currentUser } = this.props;
      const organizationId = currentUser?.organization_id;
      const response = await handleSuccessfulPayment({ organizationId });

      if (response?.plan_updated_successfully) {
        this.handlePlanConfirmation();
      }

      if (response?.plan_not_updated_successfully) {
        const subject = 'Error Updating Account Type for Customer Who Just Updated Their Plan';
        this.handlePlanConfirmationError({ subject });
      }
    }

    if (isFailedPayment) {
      const subject = 'Error Updating Plan for Customer';
      this.handlePlanUpgradeError({ subject })
    }

  }

  handleUpdateTogglePeriodState = () => {
    this.setState({ isToggleMonthly: !this.state.isToggleMonthly });
  }

  handleOnChoosePlan = async (plan) => {
    const { currentUser } = this.props;
    const { isToggleMonthly } = this.state;
    const organizationId = currentUser?.organization_id;

    const planPeriod = isToggleMonthly ? 'month' : 'year';

    const response = await goToCheckout({
      organizationId: organizationId,
      product: plan,
      planPeriod,
    });

    if (response?.url) {
      window.location.replace(response.url)
    } else if (response?.error) {
      const subject = 'Error Updating Plan for Customer';
      this.handlePlanUpgradeError({ subject });
    }
  }

  handleOnSwapPlan = async (plan) => {
    const { currentUser } = this.props;
    const { isToggleMonthly } = this.state;
    const organizationId = currentUser?.organization_id;

    const planPeriod = isToggleMonthly ? 'month' : 'year';

    const response = await swapSubscription({
      organizationId: organizationId,
      product: plan,
      planPeriod,
    });

    if (response?.plan_swapped_successfully) {
      this.handlePlanConfirmation();
    } else {
      const subject = 'Error Swapping Subscription for Customer';
      this.handlePlanConfirmationError({ subject });
    }
  }

  handleOnChooseEnterprisePlan = async (plan) => {
    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = await contactUsForEnterprise({
      organizationId,
      inquirySubject: 'upgrade to enterprise plan',
    });
  }

  handleOnCancelPlan = async (plan) => {
    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = await cancelSubscription({
      organizationId: organizationId,
      product: plan,
    });

    if (
      response?.plan_already_canceled ||
      response?.plan_canceled_successfully ||
      response?.plan_subscription_does_not_exist
    ) {
      this.handlePlanCanceledSuccessfullyModal({ response });
    }
  }

  handleOnContactUs = async (subject) => {
    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = await contactUs({
      organizationId,
      inquirySubject: subject || 'contact about plan',
    });
  }

  handleOnCancelEnterprisePlan = async () => {
    const { currentUser } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = await contactUsForEnterprise({
      organizationId,
      inquirySubject: 'cancel enterprise plan',
    });
  }

  handleConfirmChooseEnterprisePlan = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmChooseEnterprisePlanModal
            handleChoosePlan={this.handleOnChooseEnterprisePlan}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmCancelPlan = async (plan) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmCancelPlanModal
            handleCancelPlan={this.handleOnCancelPlan}
            planToCancel={plan}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmCancelEnterprisePlan = async (plan) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmCancelEnterprisePlanModal
            handleCancelPlan={this.handleOnCancelEnterprisePlan}
            planToCancel={plan}
            onClose={onClose}
          />
        );
      }
    });
  }

  handlePlanConfirmation = () => {
    const { currentUser } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <PlanIsConfirmedModal
            user={currentUser}
            onClose={onClose}
          />
        );
      }
    });
  }

  handlePlanConfirmationError = ({ subject }) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <PlanIsNotConfirmedModal
            handleContactUs={this.handleOnContactUs}
            subject={subject}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleConfirmSwapPlan = async (plan) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmSwapPlanModal
            handleChoosePlan={this.handleOnSwapPlan}
            planToChoose={plan}
            onClose={onClose}
          />
        );
      }
    });
  }

  handlePlanUpgradeError = ({ subject }) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <PlanUpgradeErrorModal
            handleContactUs={this.handleOnContactUs}
            subject={subject}
            onClose={onClose}
          />
        );
      }
    });
  }

  handlePlanCanceledSuccessfullyModal = ({ response }) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <PlanCanceledSuccessfullyModal
            handleContactUs={this.handleOnContactUs}
            response={response}
            onClose={onClose}
          />
        );
      }
    });
  }

  render() {
    const { isToggleMonthly } = this.state;
    const { currentUserOrganization, currentUser, currentLocale } = this.props;
    const { account_type: accountType } = currentUserOrganization;

    const proPrice = isToggleMonthly ? '$8.25' : '$79.20';
    const businessPrice = isToggleMonthly ? '$14.25' : '$136.80';
    const paidPlanInterval = isToggleMonthly ? 'USD/mo' : 'USD/yr';
    const canUserSwapPlan = doesUserHaveASwappablePlan({ accountType });

    return (
      <div className="settings organization-users pricing-plans container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Plans</div>
          <div className="subheader">
            <div className="page">Subscription</div>
            <div className="hyphen">-</div>
            <div className="small-title">Plan</div>
          </div>
        </div>
        <div className="content-container mt-5 px-1">
          <div className="plan-subscription-interval d-flex justify-content-center align-items-center gap-2 mb-5">
            <div className="monthly">Monthly</div>
            <div className="plan-switch form-check form-switch me-1">
              <input
                className="plan-switch-interval form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={this.handleUpdateTogglePeriodState}
              />
            </div>
            <div className="annual">Annual</div>
          </div>
          <div className="plans-table">
            <div className="row gap-5">
             <div className="col py-5 px-3">
               <div className="plan-type d-flex align-items-center mb-2">
                 <div className="h3 mb-0">Free</div>
                 {isMyPlan(accountType, 'free_trial') && (
                   <div className="ms-3 badge rounded-pill py-2 px-3">
                     Active
                   </div>
                 )}
               </div>
              <div className="plan-description pb-4">
                The quickest and easiet way to try ContactImmed
              </div>
              <div className="plan-price d-flex align-items-center mb-4">
                <div className="price h2 me-1">$0</div>
                <div className="currency">USD/mo</div>
              </div>
              <button
                type="button"
                className="select-plan btn btn-primary mt-3 mb-3"
                disabled
              >
                DEFAULT PLAN
              </button>
              <div className="plan-features mt-3">
                <div className="features-tagline"></div>
                {FREE_TRIAL_PLAN_ITEMS.map(item => (
                  <div key={item} className="feature-item d-flex mb-3">
                    <i className="bi bi-check-lg me-1"></i>
                    <div className="item">{item}</div>
                  </div>
                ))}
              </div>
             </div>
             <div className="col py-5 px-3">
              <div className="plan-type d-flex align-items-center mb-2">
                <div className="h3 mb-0">Pro</div>
                {isMyPlan(accountType, 'pro') && (
                  <div className="ms-3 badge rounded-pill py-2 px-3">
                    Active
                  </div>
                )}
              </div>
              <div className="plan-description pb-4">
                Never lose sight of the customer and watch revenue grow even
                further
              </div>
              <div className="plan-price d-flex align-items-center mb-4">
              <div className="price h2 me-1">{proPrice}</div>
              <div className="currency me-1">{paidPlanInterval}<sup>*</sup></div>
              <div className="seat">Per User</div>
              </div>
              <button
                type="button"
                className={buttonClassName(accountType, 'pro')}
                onClick={() => isMyPlan(accountType, 'pro')
                  ? this.handleConfirmCancelPlan('pro')
                  : canUserSwapPlan
                  ? this.handleConfirmSwapPlan('pro')
                  : this.handleOnChoosePlan('pro')
                }
              >
                {buttonLabel(accountType, 'pro')}
              </button>
              <div className="plan-features mt-3">
                <div className="features-tagline">
                  All the benefits of Free, and:
                </div>
                {PRO_PLAN_ITEMS.map(item => (
                  <div key={item} className="feature-item d-flex mb-3">
                    <i className="bi bi-check-lg me-1"></i>
                    <div className="item">{item}</div>
                  </div>
                ))}
              </div>
             </div>
             <div className="col py-5 px-3">
              <div className="plan-type d-flex align-items-center mb-2">
                 <div className="h3 mb-0">Business</div>
                 {isMyPlan(accountType, 'business') && (
                   <div className="ms-3 badge rounded-pill py-2 px-3">
                     Active
                   </div>
                 )}
               </div>
              <div className="plan-description pb-4">
                Scale without sales and customer success becoming siloed
              </div>
              <div className="plan-price d-flex align-items-center mb-4">
                <div className="price h2 me-1">{businessPrice}</div>
                <div className="currency me-1">{paidPlanInterval}<sup>*</sup></div>
                <div className="seat">Per User</div>
              </div>
              <button
                type="button"
                className={buttonClassName(accountType, 'business')}
                onClick={() => accountType === 'business'
                  ? this.handleConfirmCancelPlan('business')
                  : canUserSwapPlan
                  ? this.handleConfirmSwapPlan('business')
                  : this.handleOnChoosePlan('business')
                }
              >
                {buttonLabel(accountType, 'business')}
              </button>
              <div className="plan-features mt-3">
                <div className="features-tagline">
                  All the goodies of Pro, and:
                </div>
                {BUSINESS_PLAN_ITEMS.map(item => (
                  <div key={item} className="feature-item d-flex mb-3">
                    <i className="bi bi-check-lg me-1"></i>
                    <div className="item">{item}</div>
                  </div>
                ))}
              </div>
             </div>
             <div className="col py-5 px-3">
              <div className="plan-type d-flex align-items-center mb-2">
                <div className="h3 mb-0">Enterprise</div>
                {isMyPlan(accountType, 'enterprise') && (
                  <div className="ms-3 badge rounded-pill py-2 px-3">
                    Active
                  </div>
                )}

                {/*
                  <div className="plan-expiry" style={{ fontSize: 10, color: '#848484' }}>
                    Till Feb 19, 2025
                  </div>
                */}
              </div>
              <div className="plan-description pb-4">
                Experience ContactImmed without limits and have unparalleled
                synergy
              </div>
              <div className="plan-price d-flex align-items-center mb-4">
                <div className="price h2 me-1">Custom</div>
                <div className="currency">Pricing</div>
              </div>
              <button
                type="button"
                className={buttonClassName(accountType, 'enterprise')}
                onClick={() => isMyPlan(accountType, 'enterprise')
                  ? this.handleConfirmCancelEnterprisePlan('enterprise')
                  : this.handleConfirmChooseEnterprisePlan('enterprise')
                }
              >
                {buttonLabel(accountType, 'enterprise')}
              </button>
              <div className="plan-features mt-3">
                <div className="features-tagline">
                  All the joy of Business, and:
                </div>
                {ENTERPRISE_PLAN_ITEMS.map(item => (
                  <div key={item} className="feature-item d-flex mb-3">
                    <i className="bi bi-check-lg me-1"></i>
                    <div className="item">{item}</div>
                  </div>
                ))}
              </div>
             </div>
           </div>
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;
