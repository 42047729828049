// NOTE: Listed in Alphabetical Order
import { cssTransition } from 'react-toastify';

// assumes array elements are primitive types
export const areArraysEqualSets = (a1, a2) => {
  let superSet = {};
  for (let i = 0; i < a1.length; i++) {
    const e = a1[i] + typeof a1[i];
    superSet[e] = 1;
  }

  for (let i = 0; i < a2.length; i++) {
    const e = a2[i] + typeof a2[i];
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (let e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
}

export const capitalize = string => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const changeDirection = num =>
  (!num || num === 0)
    ? 'unchanged'
    : num > 0 ? 'up' : 'down';

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const ellipsis = ({ string, maxLength = 40 }) =>
  string?.length > maxLength
    ? `${string.substring(0, maxLength)}...`
    : string

export const getFormattedDate = date => {
  if (!date) { return null };
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return year + '-' + month + '-' + day;
}

export const getReactFinalFormSelectValues = list => list.map(obj => obj.value);

export const isDropdownField = fieldType =>
 fieldType && fieldType.toLowerCase() === 'dropdown';

export const isFieldEmpty = inputValue => {
  if (!inputValue) { return true };
}

export const isEmailValid = str =>
  // only check for `@` and `.` symbols
 /.+@.+\..+/i.test(str);

export const isTextField = fieldType =>
 fieldType && fieldType.toLowerCase() === 'text_input';

export const isUrlValid = url =>
  /^(ftp|http|https):\/\/[^ "]+$/.test(url);

export const numToLocaleString = num =>
  num ? num.toLocaleString() : num;

export const percentChangeFormat = num => {
  if (num !== 0 && !num) { return; }
  const formattedNumber = num % 1 === 0 ? num : num.toFixed(2);
  return num === 0
    ? `${num}%`
    : num > 0 ? `+${formattedNumber}%` : `${formattedNumber}%`;
}

export const phoneNumberValueHelper = value =>
  (value || '').charAt(0) === '+' ? value : `+${value}`

export const reactTableSortParamValue = sorted =>
 sorted.map(sort => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`).join(',');

export const sleep = ms =>
  new Promise(resolve => setTimeout(resolve, ms));

export const nativeDebounce = (callback, delay = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};

export const generateRandomId = () => {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  first = ('000' + first.toString(36)).slice(-3);
  second = ('000' + second.toString(36)).slice(-3);

  return first + second;
}

const toastifyZoom = ({ duration }) => cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  // default to 750ms, can be omitted
  duration: duration,
});

export const toastifyConfiguration = ({ duration = 750 }) => {
  return {
    toastId: generateRandomId(),
    hideProgressBar: true,
    transition: toastifyZoom({ duration }),
  };
};

export const permittedRolesList = [
  { value: 'workspace_user', label: 'workspace user' },
  { value: 'workspace_admin', label: 'workspace admin' },
  { value: 'account_user', label: 'account user' },
  { value: 'account_admin', label: 'account admin' }
];

export const lastElementOfArray = array => array.slice(-1)[0];

export const getCookieByName = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const parseQueryString = search => {
  if (!search) {
    return undefined;
  }

  let query = {};
  let pairs = (search[0] === '?' ? search.substr(1) : search).split('&');
  for (var i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
}

export const channelsFiltersQs = search => {
  return queryParamValue({ search, key: 'channels_filter' });
  // const params = new URLSearchParams(search);
  // return params.get('channels_filter');
}

export const channelsTagsFiltersQs = search => {
  return queryParamValue({ search, key: 'channels_tags_filter' });
  // const params = new URLSearchParams(search);
  // return params.get('channels_tags_filter');
}

export const workspaceIdQs = search => {
  return queryParamValue({ search, key: 'workspace_id' });
  // const params = new URLSearchParams(search);
  // return params.get('workspace_id');
}

export const queryParamValue = ({ search, key }) => {
  const params = new URLSearchParams(search);
  return params.get(key);
}

export const statusMapping = status => {
  const statusMapping = {
    ready: 'ready',
    in_progress: 'in progress',
    testing: 'testing',
    done: 'done',
    canceled: 'canceled',
    at_risk: 'at risk',
    healthy: 'healthy',
    neutral: 'neutral',
    uncategorized: 'uncategorized',
  };

  return statusMapping[status] || status;
}

export const statusBadge = status => {
  const statusMapping = {
    ready: 'bg-secondary ready-status-bg',
    in_progress: 'bg-primary in-progress-status-bg',
    testing: 'bg-info testing-status-bg',
    done: 'bg-success',
    canceled: 'bg-secondary',
    at_risk: 'bg-danger',
    healthy: 'bg-success',
    neutral: 'bg-warning',
  };

  return statusMapping[status] || 'bg-secondary ready-status-bg';
}

export const ENTERPRISE_ACCOUNT_TYPE = 'enterprise';
export const FREE_TRIAL_ACCOUNT_TYPE = 'free_trial';

export const removeEmptyFromObject = obj =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
