export const statusOptions = [
  {
    label: 'Ready',
    value: 'ready'
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Testing',
    value: 'testing',
  },
  {
    label: 'Done',
    value: 'done',
  },
  {
    label: 'Done - Bandaid Solution',
    value: 'done_bandaid',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },
];

export const booleanOptions = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
];

export const customerHealthStatusOptions = [
  {
    label: 'Healthy',
    value: 'healthy'
  },
  {
    label: 'Neutral',
    value: 'neutral',
  },
  {
    label: 'At Risk',
    value: 'at_risk',
  },
  {
    label: 'Uncategorized',
    value: 'uncategorized',
  },
];

export const FREE_TRIAL_VALUE = 'free_trial';
export const FREE_TRIAL_LABEL = 'Free Trial';

export const accountTypeOptions = [
  {
    label: 'Enterprise',
    value: 'enterprise',
  },
  {
    label: 'Business',
    value: 'business',
  },
  {
    label: 'Pro',
    value: 'pro',
  },
  {
    label: FREE_TRIAL_LABEL,
    value: FREE_TRIAL_VALUE,
  },
];

export const B2B_PERSONA_VALUE = 'b2b';
export const B2B_PERSONA_LABEL = 'B2B';

export const organizationPersonaOptions = [
  {
    label: B2B_PERSONA_LABEL,
    value: B2B_PERSONA_VALUE,
  },
  {
    label: 'Education',
    value: 'education',
  },
];
