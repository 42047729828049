import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import AddOrganizationPane from './AddOrganizationPane';
import { removeOrganization, getOrganizations } from '../../../services/organization';

import { toastifyConfiguration } from '../../../components/util/helpers';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: props.organizations?.total_pages || 0,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedOrganization: {},
      search: '',
      organizations: props.organizations?.entries || [],
      organizationsCount: props.organizations?.total_count || 0,
    };
    this.handleOnClickAddOrganization = this.handleOnClickAddOrganization.bind(this);
    this.onAddOrganizationPaneClose = this.onAddOrganizationPaneClose.bind(this);
    this.handleOnClickEditOrganization = this.handleOnClickEditOrganization.bind(this);
    this.handleOnClickRemoveOrganization = this.handleOnClickRemoveOrganization.bind(this);
    this.handleOrganizationsSearch = this.handleOrganizationsSearch.bind(this);
    this.handleOrganizationsFilterFetch = this.handleOrganizationsFilterFetch.bind(this);
    this.handleGetPaginatedOrganizations = this.handleGetPaginatedOrganizations.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    const response =  await getOrganizations({});
    if (response) {
      this.setState({ organizations: response.entries, pages: response.total_pages, totalCount: response.total_count, loading: false })
    }
  }

  handleOrganizationsSearch = event => {
    const input = event.target.value
    this.handleOrganizationsFilterFetch(input);
  }

  handleOrganizationsFilterFetch = async input => {
    event && event.preventDefault();
    const filteredOrganizations = await getOrganizations({ search: input });
    if (filteredOrganizations) {
      this.setState({ organizations: filteredOrganizations.entries || [], search: input });
    }
  }

  handleGetPaginatedOrganizations = async (page, pageSize) => {
    event && event.preventDefault();
    const { search } = this.state;
    const organizations = await getOrganizations({ page: page + 1, page_size: pageSize, search });
    organizations
      ? this.setState({ organizations: organizations.entries, pages: organizations.total_pages, organizationsCount: organizations.total_count, loading: false })
      : this.setState({ organizations: [], pages: 0, loading: false });
  }

  handleOnClickAddOrganization = () => {
    this.setState({ isPaneOpen: true, isEditMode: false });
  }

  onAddOrganizationPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditOrganization = organization => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedOrganization: organization });
  }

  handleConfirmDelete = async (organization, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveOrganization}
            subtitle="You want to delete this organization?"
            itemToRemove={organization}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveOrganization = async (organization, event) => {
    event && event.preventDefault();
    const response = await removeOrganization({
      organizationId: organization.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedOrganizations(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Organization removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the organization. Please try again.', toastifyConfiguration({}));
    }
  }

  render() {
    const { currentUser, currentLocale } = this.props;
    const { isAddMode, isEditMode, selectedOrganization, organizations, organizationsCount } = this.state;
    const columns = [
      {
        id: 'name',
        Header: 'Name',
        sortable: false,
        accessor: o => <a className="intranet-organization-name" href={`/#/${currentLocale}/intranet/organizations/${o.uuid}`}>{o.name}</a>,
      }, {
        Header: 'Account type',
        sortable: false,
        accessor: 'account_type',
      },{
        Header: 'Created at',
        sortable: false,
        accessor: 'created_at'
      }, {
        Header: 'Url',
        sortable: false,
        accessor: 'url',
      }, {
        id: 'isInternal',
        Header: 'Internal?',
        sortable: false,
        accessor: o => <span className='organization-internal'>{o.internal ? 'true' : 'false'}</span>,
      }, {
        Header: 'Organization persona',
        sortable: false,
        accessor: 'organization_persona',
      }, {
        id: 'disabled',
        Header: 'Disabled?',
        sortable: false,
        accessor: o => <span className='disabled'>{o.disabled ? 'true' : 'false'}</span>,
      }, {
        id: 'edit',
        Header: '',
        sortable: false,
        accessor: o => <span className='edit-organization' onClick={this.handleOnClickEditOrganization.bind(this, o)}>edit</span>,
      },
      {
        id: 'delete',
        Header: '',
        sortable: false,
        accessor: o => o.internal ? null : <span className='remove-organization' onClick={this.handleConfirmDelete.bind(this, o)}>delete</span>,
      }
    ];

    return (
      <div className="organizations intranet" ref={ref => this.el = ref}>
        <div className="container-fluid px-0">
          <div className="header">
            <div className="left-float">
              <div className="organization-count">{pluralize('Total Organization', organizationsCount, true)}</div>
              <div className="organization-search">
                <input
                  type="text"
                  onChange={this.handleOrganizationsSearch}
                  placeholder="search organizations" />
              </div>
            </div>
            <div className="right-float">
              <div className="add-organization">
                <Button handleClick={this.handleOnClickAddOrganization} label="Add Organization" classes="primary small" />
              </div>
            </div>
          </div>
          <ReactTable
            data={organizations}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            loading={this.state.loading}
            pages={this.state.pages}
            columns={columns}
            manual
            onFetchData={(state, instance) => {
              this.setState({ loading: true });
              this.handleGetPaginatedOrganizations(state.page, state.pageSize);
              // this.handleGetPaginatedOrganizations(state.page, state.pageSize, state.sorted, state.filtered)
            }}
          />
          <SlidingPane
            className='add-organization-pane'
            overlayClassName='sliding-pane-overlay'
            isOpen={ this.state.isPaneOpen }
            title={isEditMode ? 'Edit Organization': 'Add New Organization'}
            width='60%'
            subtitle=''
            onRequestClose={this.onAddOrganizationPaneClose}>
              <AddOrganizationPane
                handleGetPaginatedOrganizations={this.handleGetPaginatedOrganizations}
                onAddOrganizationPaneClose={this.onAddOrganizationPaneClose}
                isEditMode={isEditMode}
                selectedOrganization={selectedOrganization}
              />
          </SlidingPane>
        </div>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
