import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@material-ui/core';

import {
  CartesianGrid,
  LineChart,
  Line,
  ReferenceLine,
  Tooltip,
  YAxis,
  XAxis,
} from "recharts";

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import { fetchWorkspaceRequestCreations } from '../../../services/stats_api';

import { requestsOverTimeDemoData } from '../util/demo_data';

class RequestsCreatedOverTime extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      creationItems: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = useDemoData
      ? requestsOverTimeDemoData({ persona: 'tech' })
      : await fetchWorkspaceRequestCreations({
          workspaceId,
          organizationId,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, creationItems: response.entries })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      workspaceId,
      defaultChannelId,
      currentLocale,
      currentUser,
      handleGeneralUpgradePlanModal,
      isEducationOrganizationPersona,
    } = this.props;

    const WIDGET_TITLE = isEducationOrganizationPersona
    ?  'Requests created over the last year'
    : 'Customer requests created over the last year';

    const { isLoading, creationItems } = this.state;

    const data = creationItems;
    const isEmptyState = creationItems && !creationItems.length;

    if (isLoading) {
      return (
        <Card className="requests-created-over-time-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="requests-created-over-time-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState currentLocale={currentLocale} />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="requests-created-over-time-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <div className="d-flex pt-3 align-items-center">
              <LineChart
                width={500}
                height={140}
                data={creationItems}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
                <CartesianGrid stroke="#EBEBEB" strokeDasharray="3 3" />
                <Line
                  type="linear"
                  dataKey="count"
                  stroke="#E59B55"
                  dot={false}
                  // dot={<CustomizedDot max={entry.max_count} min={entry.min_count}/>}
                />
                <Tooltip />
                <YAxis dataKey="count" tick={{  fontSize: 12 }} />
                <XAxis dataKey="date" axisLine={false} tick={{ fontSize: 12 }} />
                <ReferenceLine label="count" stroke="#ccc" strokeDasharray="3 3" />
              </LineChart>
            </div>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

RequestsCreatedOverTime.propTypes = {};

export default RequestsCreatedOverTime;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Requests history:
    </p>
    <p className="tooltip-paragraph">
      At a glance, quickly identify spikes in requests as they may signal
      market opportunities or recently discovered pain points.
    </p>
  </div>
);

const CustomTooltip = ({ active, payload, label }) => {
  console.log('!!!!!! active', active);
  console.log('!!!!!! active', payload);
  console.log('!!!!!! label', label);
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  }

  return null;
};
