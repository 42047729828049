import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import {
  ellipsis,
  numToLocaleString,
  statusBadge,
  statusMapping,
} from '../../../components/util/helpers';
import currencyCodesHash from '../../../components/util/currency_codes_hash.json';
import { fetchWorkspaceRequestsStatusByDates } from '../../../services/stats_api';
import { unresolvedRequestsNearRenewalDemoData } from '../util/demo_data';

const widgetTitle = ({ status, periodRange }) => {
  const statusText = status === 'done'
    ? 'done'
    : status === 'testing'
    ? 'testing'
    : 'in progress';

  const periodText = periodRange === 'since_today'
    ? 'today'
    : periodRange === 'since_last_seven_days'
    ? 'in the last 7 days'
    : 'in the last 30 days';

  return `Requests updated to "${statusText}"`;
};

const buildItems = ({ entries }) => {
  const { since_today, since_last_seven_days, since_last_30_days } = entries;

  const requestsChangedToday = since_today.map(request => ({
    ...request,
    time_ago: 'today',
  }));

  return requestsChangedToday.concat(since_last_seven_days).concat(since_last_30_days);
};

class RequestsStatusByDates extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      items: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, periodRange, status, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;
    const response = useDemoData
      ? unresolvedRequestsNearRenewalDemoData({ persona: 'tech', months })
      : await fetchWorkspaceRequestsStatusByDates({
          workspaceId,
          organizationId,
          status,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({
          isLoading: false,
          items: buildItems({ entries: response.entries }),
        })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentLocale,
      currentUser,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      status,
      periodRange,
      useDemoData,
      workspaceId,
    } = this.props;

    const WIDGET_TITLE = widgetTitle({ status, periodRange });

    const { isLoading, items } = this.state;

    const data = items;
    const isEmptyState = items && !items.length;

    if (isLoading) {
      return (
        <Card className="requests-status-by-dates">
          <Card.Header className={`header ${status}`}>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
            <div className="square"></div>
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="requests-status-by-dates">
          <Card.Header className={`header ${status}`}>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
            <div className="square"></div>
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState currentLocale={currentLocale} />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="requests-status-by-dates">
          <Card.Header className={`header ${status}`}>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
            <div className="square"></div>
          </Card.Header>
          <Card.Body className="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="column-header customer-pulse-votes ps-4 pt-4"
                    >
                      Request
                    </TableCell>
                    <TableCell className="column-header pt-4">Status Last Updated</TableCell>
                    <TableCell className="column-header pt-4">Requester</TableCell>
                    <TableCell className="column-header pt-4">Updated at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell scope="row" className="dashboard-table-cell channel-path ps-3 py-3">
                        {useDemoData ? (
                          <abbr title={row.name}>
                            {
                              row.name &&
                              `${ellipsis({ string: row.name, maxLength: 40 })}`
                            }
                          </abbr>
                        ) : (
                          <a
                            href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${row.id}`}
                            target="_blank"
                          >
                            <abbr title={row.name}>
                              {
                                row.name &&
                                `${ellipsis({ string: row.name, maxLength: 40 })}`
                              }
                            </abbr>
                          </a>
                        )}
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        {row.time_ago === 'today' ? row.time_ago : `${row.time_ago} ago`}
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        tbd
                      </TableCell>
                      <TableCell className="dashboard-table-cell request-age ps-3 py-3">
                        {row.status_updated_at && moment(row.status_updated_at).utc().format('YYYY-MM-DD')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

RequestsStatusByDates.propTypes = {};

export default RequestsStatusByDates;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Requests that can impact renewals:
    </p>
    <p className="tooltip-paragraph">
      Sales and Customer Success team members can manage customers in{' '}
      <b>settings</b> and input <b>renewal dates</b> for customers.
    </p>
    <p className="tooltip-paragraph">
      By using the <b>customers</b> field when managing a request, you help
      product and delivery teams identify which requests can impact renewals.
    </p>
    <p className="tooltip-paragraph">
      This enables delivery teams to have plenty of advanced noticed in order
      to properly align their backlog. <b>Happier customers and less stress...</b>
    </p>
    <p className="tooltip-paragraph">
      Engineers would prefer to learn of renewals before the final hour 😉.
    </p>
  </div>
);

const handleCurrencySymbol = currency =>
  currency ? currencyCodesHash[currency] : '';
