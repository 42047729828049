import React from 'react';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';
import ContentContainer from './content_container';

import withRouter from '../../../util/with_router';

class Users extends React.Component {

  componentDidMount() {}

  render() {
    const { currentUser, permittedRoles, currentLocale } = this.props;

    return (
      <div className='main contactimmed-main'>
        <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale}/>
        <ContentContainer
          currentUser={currentUser}
          organizationUsers={[]}
          organizationTeams={[]}
          permittedRoles={permittedRoles}
          currentLocale={currentLocale}
        />
      </div>
    );
  }
}

export default withRouter(Users);
