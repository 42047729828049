import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Field, FormSpy } from 'react-final-form'
import Wizard from '../../components/FormWizard/wizard';

import { sleep } from '../../components/util/helpers';
import { createChannelWithoutRedux } from '../../services/channel_api';
import { assignWorkspaceToUser, upsertUser } from '../../services/organization';
import { upsertTeam } from '../../services/team';

const DEFAULT_USER_ACCOUNT_ROLE = 'account_user';
const DEFAULT_WORKSPACE_ACCOUNT_ROLE = 'workspace_user';

const onSubmit = async values => {
  await sleep(300);
  return;
}

const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span className="input-error">{error}</span> : null
    }
  />
)

const required = value => (value ? undefined : 'Required');

class ActivationWizardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestCreationSubmitting: false,
      isUserCreationSubmitting: false,
      isTeamCreationSubmitting: false,
    };
    this.handleCreateNewRequest = this.handleCreateNewRequest.bind(this);
    this.handleCreateNewUser = this.handleCreateNewUser.bind(this);
    this.handleCreateNewTeam = this.handleCreateNewTeam.bind(this);
  }

  handleCreateNewRequest = async (values) => {
    await sleep(300);
    const { requestName, requestDescription } = values || {};

    if (!requestName) {
      return;
    }

    const { workspaceId } = this.props;
    const channel = { name: requestName, description: requestDescription };

    const response = await createChannelWithoutRedux({
      channel,
      workspaceId,
    });

    if (response?.channel?.id) {
      this.setState({ newRequestId: request.channel.id });
    }
    this.setState({ isRequestCreationSubmitting: false });
  }

  handleCreateNewUser = async (values) => {
    await sleep(300);
    const {
      userAccountRole,
      userEmail,
      userFirstName,
      userLastName,
      userWorkspaceRole,
    } = values || {};

    if (!userFirstName || !userLastName || !userEmail) {
      return;
    }

    const { currentUser, workspaceId } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await upsertUser({
      organization_id,
      first_name: userFirstName,
      last_name: userLastName,
      email: userEmail,
      roles: [userAccountRole || DEFAULT_USER_ACCOUNT_ROLE],
    });

    const { id } = response || {};

    if (id) {
      this.setState({ newUserId: id });
      const assignmentResponse = await assignWorkspaceToUser({
        organization_id,
        user_id: id,
        workspace_id: workspaceId,
        role: userWorkspaceRole || DEFAULT_WORKSPACE_ACCOUNT_ROLE,
      });
    }
    this.setState({ isUserCreationSubmitting: false });
  }

  handleCreateNewTeam = async (values) => {
    await sleep(300);
    const { teamName, teamDescription } = values || {};

    if (!teamName) {
      return;
    }

    const { currentUser, workspaceId } = this.props;
    const organization_id = currentUser?.organization_id;

    const response = await upsertTeam({
      organization_id,
      name: teamName,
      description: teamDescription,
      workspace_id: workspaceId,
    });

    const { id } = response || {};

    if (id) {
      this.setState({ newTeamId: id });
    }
    this.setState({ isTeamCreationSubmitting: false });
  }

  render() {
    const {
      currentLocale,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      isEducationOrganizationPersona,
      onClose,
      workspaceId,
    } = this.props;

    const {
      isRequestCreationSubmitting,
      isTeamCreationSubmitting,
      isUserCreationSubmitting,
      newRequestId,
      newUserId,
      newTeamId,
    } = this.state;

    const canUserCreateUsersOrTeams =
      isCurrentUserAccountAdmin || isCurrentUserWorkspaceManager;

    return (
      <div className="activation-wizard-modal activation-wizard custom-ui">
        <div className="h1 mb-4">Activation Launchpad</div>
        {/*
          <div className="h1 mb-4">Activation Launchpad</div>
          <h2>Quick Start Form</h2>
        */}
        <Wizard
          initialValues={{
            userAccountRole: DEFAULT_USER_ACCOUNT_ROLE,
            userWorkspaceRole: DEFAULT_WORKSPACE_ACCOUNT_ROLE,
          }}
          onSubmit={onSubmit}
          omitFinalOnSubmit={true}
        >
          <Wizard.Page>
            <div className="text-start activation-modal-home-page">
              <div className="mb-2 wizard-section-header">
                Allow us to guide you in how to become{' '}
                <span className="bold">quickly activated</span> with ContactImmed!
              </div>
              <div className="mb-4 wizard-section-subheader">
                We'll go over the key features that will enable you to be the
                most successful. <span className="bold">Cheers to your
                organization never losing sight of the customer and "activating"
                deals because of it.</span>
              </div>
              <div className="section-header mb-4">
                This guide will cover the following:
              </div>
              <div className="section-list">
                <ol>
                  <li className="list-item">
                    Logging customer requests
                  </li>
                  <li className="list-item">
                    Inviting team members to collaborate
                  </li>
                  <li className="list-item">
                    Creating a team
                  </li>
                  <li className="list-item last">
                    Using all of the above, plus more, to enrich the dashboard
                    in order to gain a deeper awareness of business trends and
                    customer needs
                    <ol>
                      <li className="list-item first-sublist-item">
                        Understand where your customers encounter the most
                        issues, the popularity of requests, and the monetary
                        impact your efforts can have. Through this dashboard,
                        all stakeholders can quickly orient themselves and see
                        which customer voices are speaking directly to them.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </Wizard.Page>
          <Wizard.Page>
            <div className="mb-2 wizard-section-header text-start">
              Create a request
            </div>
            <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
              Requests allow you to log customer asks, pain points, and
              anything that will assist new ARR and support customer retention.
              Requests can also cover novel ideas from internal team members.
            </div>
            <div className="fields">
              <div className="d-flex flex-column text-start mb-4">
                <div className="field-container">
                  <label className="mb-2">Request name</label>
                  <Field
                    name="requestName"
                    component="input"
                    className="form-control"
                    type="text"
                    placeholder="Request name"
                    validate={required}
                  />
                </div>
                <Error name="requestName" />
              </div>
              <div className="d-flex flex-column text-start mb-2">
                <div className="field-container">
                  <label className="mb-2">Request description (optional)</label>
                  <Field
                    name="requestDescription"
                    component="textarea"
                    className="form-control"
                    type="text"
                    placeholder={`# Description of what the customer wants`}
                  />
                </div>
                <Error name="requestDescription" />
              </div>
            </div>
            {!newRequestId ? (
              <div className="text-start py-4 px-3 step-completion-container mt-4">
                Congratulations on successfully creating a new request. You can
                create additional requests via the workspace here {' '}
                <a
                  href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${newRequestId}`}
                  target="_blank"
                >
                  <i className="bi bi-box-arrow-up-right"></i>
                </a>
              </div>
            ) : (
              <FormSpy subscription={{ values: true }}>
                {({ values }) => isRequestCreationSubmitting ? (
                  <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting" disabled={true}>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Submitting...</span>
                  </button>
                ) : (
                  <button
                    className="submit-button mt-4"
                    onClick={() => {
                    this.setState({ isRequestCreationSubmitting: true });
                    this.handleCreateNewRequest(values);
                  }}>
                    Create request
                  </button>
                )}
              </FormSpy>
            )}
          </Wizard.Page>
          {canUserCreateUsersOrTeams && (
            <Wizard.Page
              validate={values => {
                const errors = {}
                if (!values.userEmail) {
                  errors.userEmail = 'Required'
                }
                if (!values.userFirstName) {
                  errors.userFirstName = 'Required'
                }
                if (!values.userLastName) {
                  errors.userLastName = 'Required'
                }
                return errors
              }}
            >
              <div className="mb-2 wizard-section-header text-start">
                Create a user
              </div>
              <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
                Add a user to this workspace and quickly begin collaborating to solve
                real customer problems and grow your enterprise value. Users should
                include sales, customer success, delivery teams, and leadership.
              </div>
              <div className="fields">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column text-start mb-4">
                    <div className="field-container">
                      <label className="mb-2">First name</label>
                      <Field
                        name="userFirstName"
                        component="input"
                        className="form-control activation-wizard-new-user-name"
                        type="text"
                        placeholder="First name"
                        validate={required}
                      />
                    </div>
                    <Error name="userFirstName" />
                  </div>
                  <div className="d-flex flex-column text-start mb-4">
                    <div className="field-container">
                      <label className="mb-2">Last name</label>
                      <Field
                        name="userLastName"
                        component="input"
                        className="form-control activation-wizard-new-user-name"
                        type="text"
                        placeholder="Last name"
                        validate={required}
                      />
                    </div>
                    <Error name="userLastName" />
                  </div>
                </div>
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    <label className="mb-2">Email</label>
                    <Field
                      name="userEmail"
                      component="input"
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      validate={required}
                    />
                  </div>
                  <Error name="userEmail" />
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex flex-column text-start">
                    <div className="field-container">
                      <label className="mb-2">Account role</label>
                      <Field
                        name="userAccountRole"
                        component="select"
                        className="form-select activation-wizard-new-user-role"
                      >
                        <option value="account_user">Account user</option>
                        <option value="account_admin">Account admin</option>
                      </Field>
                    </div>
                    <Error name="userAccountRole" />
                  </div>
                  <div className="d-flex flex-column text-start">
                    <div className="field-container">
                      <label className="mb-2">Workspace role</label>
                      <Field
                        name="userWorkspaceRole"
                        component="select"
                        className="form-select activation-wizard-new-user-role"
                      >
                        <option value="workspace_user">Workspace user</option>
                        <option value="workspace_manager">Workspace manager</option>
                      </Field>
                    </div>
                    <Error name="userWorkspaceRole" />
                  </div>
                </div>
              </div>
              {!newUserId ? (
                <div className="text-start py-4 px-3 step-completion-container mt-4">
                  Congratulations on successfully creating a new user. Users
                  can be mentioned in Requests message, triggering notifications
                  to the user for their immediate attention. You create additional
                  users here via user settings here {' '}
                  <a
                    href={`/#/${currentLocale}/settings/users`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              ) : (
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => isUserCreationSubmitting ? (
                    <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting">
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      <span>Submitting...</span>
                    </button>
                  ) : (
                    <button
                      className="submit-button mt-4"
                      onClick={() => {
                      this.setState({ isUserCreationSubmitting: true });
                      this.handleCreateNewUser(values);
                    }}>
                      Create user
                    </button>
                  )}
                </FormSpy>
              )}

            </Wizard.Page>
          )}
          {canUserCreateUsersOrTeams && (
            <Wizard.Page>
              <div className="mb-2 wizard-section-header text-start">
                Create a team
              </div>
              <div className="mb-4 wizard-section-subheader bg text-start py-4 px-3">
                Teams are where users can be grouped. Members
                can be added to teams based on areas of responsibility and parts
                of the product they own.
              </div>
              <div className="fields">
                <div className="d-flex flex-column text-start mb-4">
                  <div className="field-container">
                    <label className="mb-2">Team name</label>
                    <Field
                      name="teamName"
                      component="input"
                      className="form-control"
                      type="text"
                      placeholder="Team name"
                      validate={required}
                    />
                  </div>
                  <Error name="teamName" />
                </div>
                <div className="d-flex flex-column text-start mb-2">
                  <div className="field-container">
                    <label className="mb-2">Team description</label>
                    <Field
                      name="teamDescription"
                      component="textarea"
                      className="form-control"
                      type="text"
                      placeholder={`# e.g. (Optional) Description of what the team`}
                    />
                  </div>
                  <Error name="teamDescription" />
                </div>
              </div>
              {!newTeamId ? (
                <div className="text-start py-4 px-3 step-completion-container mt-4">
                  Congratulations on successfully creating a team. Team tags
                  allow you to easily "assign" Requests to groups with ownership
                  of an area. You create additional teams here via team settings here {' '}
                  <a
                    href={`/#/${currentLocale}/settings/teams`}
                    target="_blank"
                  >
                    <i className="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              ) : (
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => isTeamCreationSubmitting ? (
                    <button type="button" className="submit-button activation-wizard-submit-step-button py-2 mt-4 submitting">
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      <span>Submitting...</span>
                    </button>
                  ) : (
                    <button
                      className="submit-button mt-4"
                      onClick={() => {
                      this.setState({ isTeamCreationSubmitting: true });
                      this.handleCreateNewTeam(values);
                    }}>
                      Create team
                    </button>
                  )}
                </FormSpy>
              )}
            </Wizard.Page>
          )}
          <Wizard.Page>
            <div className="text-start activation-modal-home-page">
              <div className="h3 mb-4 wizard-section-header">
                Mission complete!
              </div>
              <div className="mb-4 wizard-section-subheader bg py-4 px-3">
                Now that you've experienced key features, you're ready to
                get started. Please open the Request here{' '}
                <a
                  href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/${newRequestId}`}
                  target="_blank"
                >
                  <i className="bi bi-box-arrow-up-right"></i>
                </a>
              </div>
              <div className="mb-4 wizard-section-subheader">
                Please remember. Enriching requests with details is the best way
                to:
              </div>
              <div className="section-list">
                <ul>
                  <li className="list-item">
                    Letting your coworkers know the deal value behind the request,
                    which customers are speaking, and other information that’ll
                    quickly route the customer's voice to the right stakeholders who
                    can get tasks done.
                  </li>
                  <li className="list-item">
                    Suggest <b>teams</b> and mention users. Identify features
                    and assets that'll aid in analytical insights.
                  </li>
                </ul>
              </div>
              {/*
                <div className="mb-4 wizard-section-subheader">
                  Letting your coworkers know the deal value behind the request,
                  which customers are speaking, and other information that’ll
                  quickly route the customer's voice to the right stakeholders who
                  can get tasks done.
                </div>
                <div className="mb-4 wizard-section-subheader">
                  Suggest <b>teams</b> and mention users. Identify features
                  and assets that'll aid in analytical insights.
                </div>
              */}

              <div className="mb-4 wizard-section-subheader">
                You're on your way to elevating customer insights and satisfaction.
              </div>
            </div>
          </Wizard.Page>
        </Wizard>
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-2 close-wizard-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

ActivationWizardModal.propTypes = {
  handleChoosePlan: PropTypes.func,
  planToChoose: PropTypes.string,
  onClose: PropTypes.func,
};

export default ActivationWizardModal;
