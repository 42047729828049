import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationCustomerTags } from '../../../actions/organization_actions';

const msp = (state, ownProps) => {
  return {
    currentUser: state.entities.currentUser,
    tags: state.entities.organization.customerTags,
    pages: state.entities.organization.pages || 1,
    tagsCount: state.entities.organization.customerTagsCount || 0,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchCustomerTags: ({ page, pageSize, search }) =>
      dispatch(fetchOrganizationCustomerTags({ organizationId, page, pageSize, search })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
