import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  sleep,
  composeValidators,
  isEmailValid,
  toastifyConfiguration,
  areArraysEqualSets,
} from '../../components/util/helpers';
import { upsertTeam, updateTeam } from '../../services/team';

toast.configure();

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

class AddTeamPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const {
      onAddTeamPaneClose,
      currentUser,
      isEditMode,
      selectedTeam,
      handleGetPaginatedTeams,
     } = this.props;
    const { description, name, tagList, workspace } = values;
    const { tags } = selectedTeam;

    const tagValues = (tagList || []).map(tag => ({
      name: tag.label,
      tag_type: 'team',
    }));

    const allTagsRemoved = (tags && tags.length > 0)  && tagValues.length === 0;

    const team = isEditMode
      ? await updateTeam({
          organization_id: currentUser?.organization_id,
          team_id: selectedTeam.id,
          ...(description && { description }),
          ...(name && { name }),
          ...((tagList || allTagsRemoved) && { tag_list: tagValues }),
          ...(workspace && { workspace_id: workspace.value }),
        })
      : await upsertTeam({
          organization_id: currentUser?.organization_id,
          description,
          name,
          ...(tagList && { tag_list: tagValues }),
          ...(workspace && { workspace_id: workspace.value }),
        });

    if (team && !(team || {}).error) {
      onAddTeamPaneClose();
      await handleGetPaginatedTeams(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      const action = isEditMode ? 'updated' : 'added';
      toast.success(`Team, ${team?.name}, ${action} successfully!`, toastifyConfiguration({}));
    } else {
      const action = isEditMode ? 'updating' : 'adding';
      toast.error(`Error ${action} team, ${name}. Please try again.`, toastifyConfiguration({}));
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      isEditMode,
      selectedTeam,
      permittedRoles,
      teamTagOptions,
      workspacesAvailable,
    } = this.props;
    const teamTags = (selectedTeam.tags || []).map(tag => ({
      label: tag.name,
      value: tag.name,
    }));
    const { isSubmitting } = this.state;
    const { roles } = selectedTeam;
    const ROLE_TYPES = permittedRoles.map(role => role.value);

    const checkAllInitialValue = roles && areArraysEqualSets(roles, ROLE_TYPES)
      ? [true]
      : undefined;
    const rolesInitialValue = roles || undefined;

    const workspaceOptions = workspacesAvailable.map(workspace => ({
      value: workspace.id, label: workspace.name,
    }));

    const initialWorkspaceValue = selectedTeam.workspace_id
      ? workspaceOptions.find(workspace => workspace.value === selectedTeam.workspace_id)
      : undefined;

    return (
      <div className="add-team-pane">
        <Form
          onSubmit={(values) => {
            this.setState({ isSubmitting: true });
            this.handleOnSubmit(values);
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-fields-container">
                <div className="team-info-section">
                  <div className="fields">
                    <div className="field-container mb-3">
                      <label>Name</label>
                      <Field
                        name="name"
                        validate={required}
                        initialValue={isEditMode ? selectedTeam.name : undefined}
                      >
                        {({ input, meta }) => (
                          <p className="field name">
                            <input {...input} type="text" placeholder="Team name" />
                            {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                          </p>
                        )}
                      </Field>
                    </div>
                    <div className="field-container mb-3">
                      <label>Description (optional)</label>
                      <Field
                        name="description"
                        initialValue={isEditMode ? selectedTeam.description : undefined}
                      >
                        {({ input, meta }) => (
                          <p className="field description">
                            <textarea {...input} placeholder="Description of team" />
                            {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
                          </p>
                        )}
                      </Field>
                    </div>
                    <div className="field-container mb-3">
                      <label>Workspace</label>
                      <Field
                        name="workspace"
                        initialValue={initialWorkspaceValue}
                        // value={teamTags}
                        component={ReactSingleSelectAdapter}
                        className="select-field workspace-selector"
                        options={workspaceOptions}
                        placeholder="None"
                      />
                    </div>
                    <div className="field-container">
                      <label>Tags</label>
                      <Field
                        name="tagList"
                        initialValue={teamTags}
                        value={teamTags}
                        component={ReactSelectAdapter}
                        className="select-field tags-selector"
                        options={teamTagOptions}
                        placeholder="None"
                      />
                    </div>
                  </div>
                  <div className="submit">
                    {isSubmitting ? (
                      <div className="submit-button submitting d-flex align-items-center justify-content-center">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span>Submitting...</span>
                      </div>
                    ) : (
                      <input type="submit" name="commit" value="Submit" disabled={submitting} />
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

AddTeamPane.propTypes = {
  handleGetPaginatedTeams: PropTypes.func,
};

export default AddTeamPane;

// private

const required = value => value ? undefined : 'Required';
const mustBeValidEmail = value => isEmailValid(value) ? undefined : 'Must be a valid email';

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select">
    <CreatableSelect {...input} {...rest} isMulti />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
);

const ReactSingleSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select">
    <Select {...input} {...rest} />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
);
