import React, { Fragment } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddTeamPane from '../../../components/pane/AddTeamPane';
import { removeTeam, getTeams, getTeamsCsv } from '../../../services/team';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import AboutPageDrawer from '../../AboutDrawers/TeamsSettingsPage/AboutPageDrawer';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.organizationTeams.total_pages,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedTeam: {},
      search: '',
      teams: [], // props.organizationTeams.entries,
      teamsCount: 0, // props.organizationTeams.total_count,
      isAboutPageDrawerOpen: false,
      isCsvActionInProgress: false,
    };
    this.handleOnClickAddTeam = this.handleOnClickAddTeam.bind(this);
    this.onAddTeamPaneClose = this.onAddTeamPaneClose.bind(this);
    this.handleOnClickEditTeam = this.handleOnClickEditTeam.bind(this);
    this.handleOnClickRemoveTeam = this.handleOnClickRemoveTeam.bind(this);
    this.handleTeamsSearch = this.handleTeamsSearch.bind(this);
    this.handleTeamsFilterFetch = this.handleTeamsFilterFetch.bind(this);
    this.handleGetPaginatedTeams = this.handleGetPaginatedTeams.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  async componentDidMount() {
    Modal.setAppElement(this.el);
    await this.props.fetchTags({ tagType: 'team' });
  }

  debounceSearch = debounce(input => this.handleTeamsFilterFetch(input), 500);

  handleTeamsSearch = event => {
    const input = event?.target?.value;
    if (!input) {
      return;
    }

    this.debounceSearch(input);
  }

  handleTeamsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredTeams = await this.props.fetchTeams({ search: input });
    if (filteredTeams) {
      this.setState({ teams: filteredTeams.entries || [], search: input });
    }
  }

  handleGetPaginatedTeams = async (page, pageSize) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchTeams({
      page: page + 1,
      pageSize: pageSize,
      search,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddTeam = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedTeam: {}  });
  }

  onAddTeamPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditTeam = team => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedTeam: team });
  }

  handleConfirmDelete = async (team, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveTeam}
            subtitle="You want to delete this team?"
            itemToRemove={team}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveTeam = async (team, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const response = await removeTeam({
      organization_id: currentUser?.organization_id,
      team_id: team.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedTeams(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Team removed successfully.', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the team. Please try again.', toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getTeamsCsv({ organization_id: currentUser?.organization_id });
    await sleep(300);
    this.setState({ isCsvActionInProgress: false });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    let {
      organizationTeams,
      currentUser,
      teams,
      teamsCount,
      pages,
      permittedRoles,
      teamTags,
      currentLocale,
      workspacesAvailable,
    } = this.props;

    const teamTagOptions = teamTags.map(tag => ({
      label: tag.name,
      value: tag.name,
    })) || [];

    let {
      isAddMode,
      isCsvActionInProgress,
      isEditMode,
      selectedTeam,
    } = this.state;

    const columns = [{
      id: 'name',
      Header: 'Name',
      sortable: false,
      accessor: t => <a className='team-name' href={`/#/${currentLocale}/settings/teams/${t.id}`}>{t.name}</a>
    }, {
      id: 'workspace',
      Header: 'Workspace',
      sortable: false,
      accessor: team => (
        <span className='user-count'>{getWorkspaceName({ workspacesAvailable, team })}</span>
      ),
    }, {
      id: 'created_at',
      Header: 'Created At',
      sortable: false,
      accessor: t => <span>{moment(t.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'user_count',
      Header: 'Member Count',
      sortable: false,
      accessor: t => <span className='user-count'>{(t.user_count)}</span>
    }, {
      id: 'tags_list',
      Header: 'Tags',
      sortable: false,
      accessor: t => (
        <span className='team-tags'>
          {(t.tags || []).map(tag => tag.name).join(', ')}
        </span>
      )
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: t => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditTeam.bind(this, t)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, t)}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }]

    return (
      <div className="settings organization-teams container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Teams</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                {pluralize('Total Team', teamsCount, false)}
                <div className="count-container">{teamsCount}</div>
              </div>
            </div>
            <div className="summary-actions">
              <div className="search">
                <input
                  type="text"
                  className="form-control search-input"
                  onChange={this.handleTeamsSearch}
                  placeholder="Search teams" />
              </div>
              <div className="ms-2 button-actions">
                <Button handleClick={this.handleOnClickAddTeam} label="Add New" classes="primary small add-new" />
                {isCsvActionInProgress ? (
                  <button className="export small export-csv" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <span>Exporting...</span>
                  </button>
                ) : (
                  <Button
                    handleClick={() => {
                      this.setState({ isCsvActionInProgress: true });
                      this.handleCsvExport();
                    }}
                    label="Export CSV"
                    classes="export small export-csv"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={teams}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedTeams(state.page, state.pageSize);
              }}
              getTableProps={() => {
                return {
                  style: { overflow: 'visible' }
                };
              }}
              getTbodyProps={() => {
                return {
                  style: { overflow: 'visible' }
                };
              }}
              getTdProps={(state, rowInfo, column) => {
                const { id } = column;
                return {
                  style: id === 'tags_list' ? { overflow: 'visible' } : {}
                };
              }}
            />
            <SlidingPane
              className='add-team-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={isEditMode ? 'Edit Team' : 'Create New Team'}
              width='60%'
              subtitle=''
              onRequestClose={this.onAddTeamPaneClose}>
                <AddTeamPane
                  handleGetPaginatedTeams={this.handleGetPaginatedTeams}
                  currentUser={currentUser}
                  onAddTeamPaneClose={this.onAddTeamPaneClose}
                  isEditMode={isEditMode}
                  selectedTeam={selectedTeam}
                  permittedRoles={permittedRoles}
                  teamTagOptions={teamTagOptions}
                  workspacesAvailable={workspacesAvailable}
                />
            </SlidingPane>
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings teams-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;

const getWorkspaceName = ({ workspacesAvailable, team }) => {
  const workspace = workspacesAvailable.find(workspace => workspace.id === team.workspace_id);
  return workspace ? workspace.name : '';
};
