import { paramsToQueryString } from '../services/util/helpers';

export async function fetchUsers({
  search,
  pageSize,
  page,
  organizationId,
}) {
  const params = { search, page, page_size: pageSize };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organizationId}/users.json`
    : `/organizations/${organizationId}/users.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchTeams({
  search,
  pageSize,
  page,
  organizationId,
}) {
  const params = { search, page, page_size: pageSize };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organizationId}/teams.json`
    : `/organizations/${organizationId}/teams.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchTeam({
  teamId,
}) {
  const url = `/settings/teams/${teamId}.json`

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaces({
  search,
  pageSize,
  page,
  organizationId,
}) {
  const params = { search, page, page_size: pageSize };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organizationId}/workspaces.json`
    : `/organizations/${organizationId}/workspaces.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspace({
  workspaceId,
}) {
  const url = `/settings/workspaces/${workspaceId}.json`;

  return $.ajax({
    url: url,
  });
}

export async function fetchOrganizationWorkspaceTags({
  search,
  organizationId,
  tagType,
  workspaceId,
}) {
  // TODO: make page size configurable
  const pageSize = 100;
  const params = {
    search,
    tag_type: tagType,
    workspace_id: workspaceId,
    page_size: pageSize,
  };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organizationId}/tags.json?workspace_id=${workspaceId}`
    : `/organizations/${organizationId}/tags.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchOrganizationCustomerTags({
  search,
  organizationId,
  pageSize,
  page,
}) {
  const params = {
    search,
    page_size: pageSize,
    page: page
  };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organizationId}/tags/customers.json`
    : `/organizations/${organizationId}/tags/customers.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchOrganizationRequests({
  filters,
  search,
  organizationId,
  workspaceId,
  pageSize,
  page,
}) {
  const params = {
    ...filters,
    search,
    page_size: pageSize,
    page: page,
    workspace_id: workspaceId,
  };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organizationId}/requests.json`
    : `/organizations/${organizationId}/requests.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchOrganizationTagsAcrossWorkspaces({
  organizationId,
}) {
  const url = `/organizations/${organizationId}/tags/across_workspaces.json`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}


export async function updateTag({
  organizationId,
  tagId,
  customerRenewalDate,
  customerHealthStatus,
  name,
  disabled,
}) {
  const response = await fetch(`/organizations/${organizationId}/tags/${tagId}`, {
    method: 'put',
    body: JSON.stringify({
      name,
      customer_renewal_date: customerRenewalDate,
      customer_health_status: customerHealthStatus,
      disabled: disabled,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export const removeTag = ({ organizationId, tagId }) => {
  return $.ajax({
    url: `/organizations/${organizationId}/tags/${tagId}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'DELETE'
  });
};

export async function getCustomerTagsCsv({ organizationId }) {
  const url = `/organizations/${organizationId}/tags/download_customer_tags`;

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organizationId}/tags/customers.csv`;
  });
  return;
}


export async function fetchUserMentions({
  search,
  organizationId,
}) {
  const params = { search };
  const url = !paramsToQueryString(params)
    ? `/api/organizations/${organizationId}/mentions.json`
    : `/api/organizations/${organizationId}/mentions.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchEndpoints({
  search,
  pageSize,
  page,
  workspaceId,
  organizationId,
}) {
  const params = {
    search,
    page,
    page_size: pageSize,
    workspace_id: workspaceId,
  };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organizationId}/webhooks.json`
    : `/organizations/${organizationId}/webhooks.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url: url,
  });
}

export const getOrganizationInvitations = ({
  organizationId,
  status,
  page,
  pageSize,
  search,
}) => {
  const params = { page, page_size: pageSize, search, status };
  const url = !paramsToQueryString(params)
    ? `/api/organizations/${organizationId}/invitations.json`
    : `/api/organizations/${organizationId}/invitations.json?${paramsToQueryString(params)}`;

  return $.ajax({
    url,
  });
}

export const updateOrganizationInvitation = ({ organizationId, id, approved, revoked }) => {
  return $.ajax({
    url: `/api/organizations/${organizationId}/invitations/${id}`,
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Rails.csrfToken());
    },
    method: 'PUT',
    contentType: 'application/json',
    data: JSON.stringify({ invitation: { approved, revoked } }),
  });
}
