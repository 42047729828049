import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner';

import MaterialUiTooltip from './MaterialUiTooltip';
import NoDataAvailableWidgetState from './NoDataAvailableWidgetState';

import { capitalize, ellipsis, numToLocaleString, statusBadge, statusMapping } from '../../../components/util/helpers';
import { fetchWorkspaceRequestsByCustomerRiskLevels } from '../../../services/stats_api';
import { requestsByCustomerRiskLevelsDemoData } from '../util/demo_data';

class RequestsByCustomerRiskLevelCard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      atRiskItems: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { currentUser, useDemoData, workspaceId } = this.props;
    const organizationId = currentUser?.organization_id;

    const response = useDemoData
      ? requestsByCustomerRiskLevelsDemoData({ persona: 'tech' })
      : await fetchWorkspaceRequestsByCustomerRiskLevels({
          workspaceId,
          organizationId,
        });

    if (this._isMounted) {
      if (!(response || {}).error) {
        this.setState({ isLoading: false, atRiskItems: response.entries })
      } else {
        this.setState({ isLoading: false, isError: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      currentLocale,
      currentUser,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      useDemoData,
      workspaceId,
    } = this.props;

    const WIDGET_TITLE = 'Requests by customer status';
    const { atRiskItems, isLoading } = this.state;
    const data = atRiskItems;
    const isEmptyState = atRiskItems && !atRiskItems.length;

    if (isLoading) {
      return (
        <Card className="requests-by-customer-risk-level-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center" as="div">
              <Spinner animation="border" role="status" variant="secondary" />
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }

    if (!isLoading && isEmptyState) {
      return (
        <Card className="requests-by-customer-risk-level-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content align-items-center">
            <Card.Text className="text-center empty-state-container" as="div">
              <NoDataAvailableWidgetState currentLocale={currentLocale} />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }

    if (!isLoading && !isEmptyState) {
      return (
        <Card className="requests-by-customer-risk-level-card">
          <Card.Header>
            <div className="title">{WIDGET_TITLE}</div>
            <MaterialUiTooltip titleContent={titleContent()} />
          </Card.Header>
          <Card.Body className="card-content">
            <TableContainer component={Paper} className="shadow-none">
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableBody>
                  {data?.map((row) => (
                    <TableRow
                      key={row.customer_health_status || 'uncategorized'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className="dashboard-table-cell pe-0 ps-0">
                        {useDemoData ? (
                          <div className="d-flex justify-content-between align-items-center shadow-sm p-3 rounded">
                            <div className={`status d-flex justify-content-center align-items-center`}>
                              {capitalize(statusMapping(row.customer_health_status || 'uncategorized'))}
                            </div>
                            <div>
                              {row.active_requests_count
                                ? `${numToLocaleString(row.active_requests_count)}`
                                : 0
                              }
                            </div>
                          </div>
                        ) : (
                          <a
                            href={`/#/${currentLocale}/workspaces/${workspaceId}/requests/filter?customer_health_status=${row.customer_health_status || 'uncategorized'}`}
                            target="_blank"
                          >
                            <div className="d-flex justify-content-between align-items-center shadow-sm p-3 rounded">
                              <div className={`status d-flex justify-content-center align-items-center`}>
                                {capitalize(statusMapping(row.customer_health_status || 'uncategorized'))}
                              </div>
                              <div>
                                {row.active_requests_count
                                  ? `${numToLocaleString(row.active_requests_count)}`
                                  : 0
                                }
                              </div>
                            </div>
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card.Body>
          <Card.Footer>
            <Card.Text
              className="dashboard-create-channel-action"
              onClick={() => handleGeneralUpgradePlanModal({})}
            >
              Create a Request
            </Card.Text>
          </Card.Footer>
        </Card>
      );
    }
  }
}

RequestsByCustomerRiskLevelCard.propTypes = {};

export default RequestsByCustomerRiskLevelCard;

const titleContent = () => (
  <div className="tooltip-content">
    <p className="tooltip-paragraph header">
      Customers at risk:
    </p>
    <p className="tooltip-paragraph">
      Sales and Customer Success team members can manage customers in{' '}
      <b>settings</b> and identify which customers, or prospects, are "at risk"
      of not renewing or signing a deal.
    </p>
    <p className="tooltip-paragraph">
      By using the <b>customers</b> field when managing a request, you help
      leadership and delivery teams easily identify which requests to prioritize
      in order to show "at risk" customers that you care and enhance the
      probability of securing deals.
    </p>
  </div>
);
