// jscs:disable maximumLineLength
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AtomicBlockUtils,
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import withRouter from '../../../../util/with_router';
import { uploadMediaFileToS3 } from '../../../../services/media_files_api';
import { handleUserMentions } from '../../../../services/channel_api';

const urlCreator = window.URL || window.webkitURL;

class MessageForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editorState: this.props.defaultMessage
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(this.props.defaultMessage.content)
          )
        )
      : EditorState.createEmpty(),
    };
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    this.handleDroppedFiles = this.handleDroppedFiles.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.createSocket();
  }

  componentDidMount() {
    // TODO: make dynamic suggestions (see the servies file for page size)
    this.props.fetchUserMentions();
    document.querySelector('.rdw-editor-wrapper')?.addEventListener('keydown', event => {
      // TODO: option to send event with this. For now, will test new lines and attachments
      // if (event.key === 'Enter' && !event.ctrlKey) {
      //   event.preventDefault()
      //   this.handleSendEvent(event);
      // }

      // TODO: option for new line with control + return keys (similar to Slack)
      // if (event.key === 'Enter' && !event.ctrlKey) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault()
        this.handleSendEvent(event);
      }
    });
  }

  componentWillUnmount() {
    document.querySelector(
      '.rdw-editor-wrapper'
    )?.removeEventListener('keydown', this.handleSendEvent);
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };

  createSocket() {
    const { channel, currentUser, workspaceId, actionCabelUrl } = this.props;
    const organizationId = currentUser?.organization_id;

    let cable;
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'qa' ||
      process.env.NODE_ENV === 'production'
    ) {
      cable = ActionCable.createConsumer(actionCabelUrl);
    } else {
      return;
    }

    this.chats = cable?.subscriptions?.create({
      channel: 'ChatChannel',
      // id: channel.id,
    }, {
      connected: () => {},
      received: (data) => {
        this.props.newMessage(data);
      },
      create: function ({ chatContent, authorId, channelId, formData }) {
        this.perform('create', {
          content: chatContent,
          author_id: authorId,
          organization_id: organizationId,
          workspace_id: workspaceId,
          channel_id: channelId,
          form_data: formData,
        });
      },
      update: function ({ chatContent, authorId, channelId, messageId }) {
        this.perform('update', {
          content: chatContent,
          author_id: authorId,
          organization_id: organizationId,
          workspace_id: workspaceId,
          channel_id: channelId,
          message_id: messageId,
        });
      },
    });
  }

  handleSendEvent = async (event) => {
    const { editorState } = this.state;
    const {
      currentUser,
      workspaceId,
      channel,
      defaultMessage,
      isEditMode,
    } = this.props;

    const organizationId = currentUser?.organization_id;

    event.preventDefault();
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const convertToRawVar = convertToRaw(editorState.getCurrentContent());

    // const isBlockEmpty =
    //   convertToRawVar.blocks.length === 1 &&
    //   convertToRawVar.blocks[0].text.replaceAll('\n', '') === '';

    const mentionsExtracted = [];
    const entityMap = convertToRaw(editorState.getCurrentContent()).entityMap;

    let notificationBlock = [];
    const block = convertToRawVar.blocks.map(block => {
      const { text } = block;
      notificationBlock.push(text);
      return text.replaceAll('\n', '');
    }).filter(text => text);

    Object.values(entityMap).forEach(entity => {
      if (entity.type === 'MENTION') {
        mentionsExtracted.push(entity.data.value);
      }
    });

    const isBlockEmpty = block.length === 0;

    if(!isBlockEmpty) {
      if (isEditMode) {
        this.chats.update({
          chatContent: html,
          authorId: currentUser.id,
          channelId: channel.id,
          messageId: defaultMessage.id
        });
      } else {
        this.chats.create({
          chatContent: html,
          authorId: currentUser.id,
          channelId: channel.id,
        });
      }

      this.setState({
        editorState: EditorState.createEmpty(),
      });
      if (mentionsExtracted.length > 0) {
        await handleUserMentions({
          organizationId,
          workspaceId,
          mentions: mentionsExtracted,
          channelId: channel.id,
          // consider sending html, etc... (depends on integration)
          text: notificationBlock.filter(text => text).join(' '),
        });
      }
    }
  }

  uploadImageCallBack = async (file) => {
    if (file) {
      const { currentUser, workspaceId } = this.props;
      const organizationId = currentUser?.organization_id;
      const formData = new FormData();
      formData.append('file', file);

      const response =
        await uploadMediaFileToS3({ organizationId, workspaceId, formData });

      let url = '';
      if (!(response || {}).error) {
        const { bucket_name, key } = response;
        url = `https://${bucket_name}.s3.amazonaws.com/${key}`;
      }

      if (!url) {
        return 'not_handled';
      }

      return new Promise(
        (resolve, reject) => {
          resolve({ data: { link: url } });
        }
      );
    }
  }

  handleOnClear = () => {
    const { handleClearingEditMessageId, isEditMode } = this.props;
    this.setState({
      editorState: EditorState.createEmpty(),
    });
    isEditMode && handleClearingEditMessageId({ resetScroll: true });
  }

  handleOnSave = event => {
    const { handleClearingEditMessageId, isEditMode } = this.props;
    this.handleSendEvent(event);
    isEditMode && handleClearingEditMessageId({});
  }

  handleDroppedFiles = async (selection, files) => {
    const { editorState } = this.state;

    const file = files && files[0]
    if (file.type.startsWith('image')) {
      const newFile = await this.uploadImageCallBack(file);
      const entityData = { src: newFile.data.link, height: newFile.data?.metadata?.height || '320px', width: newFile.data?.metadata?.width || '320px' };
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', entityData);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        EditorState.createWithContent(contentStateWithEntity), entityKey, ' ',
      );
      return this.setState({ editorState: newEditorState });
    }
  }

  handleSuggestionsList() {
    const { mentions } = this.props;
    return mentions.map(mention => ({
      text: `${mention.username}`,
      value: `${mention.username}`,
      url: '',
    }));
  }

  render() {
    let channelName = '';

    const { channel, mentions, defaultMessage } = this.props;
    const { editorState } = this.state;

    if (!channel) {
      return null;
    }

    return (
      <div className="chat-stage rich-text-editor-container">
        <div className="chat-input-container">
          <Editor
            editorState={editorState}
            toolbarClassName="channel-rte-toolbar"
            wrapperClassName="chat-input-wrapper channel-rte-wrapper"
            editorClassName="chat-input channel-rte-main-editor"
            onEditorStateChange={(e) => this.onEditorStateChange(e)}
            placeholder={`Welcome! To learn how to use ContactImmed to skyrocket customer empathy and deliver value to your organization, please click "Pro Tip"`}
            mention={{
              separator: ' ',
              trigger: "@",
              suggestions: this.handleSuggestionsList(),
            }}
            localization={{
              locale: 'en',
            }}
            handleDroppedFiles={(selected, files) => this.handleDroppedFiles(selected, files)}
            toolbar={{
              // ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
              options: ['inline', 'list', 'link', 'emoji', 'image'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
              urlEnabled: false,
              list: {
                options: ['unordered', 'ordered'],
                unordered: { className: 'i-unordered-icon' },
                ordered: { className: 'i-ordered-icon' },
              },
              image: {
                previewImage: true,
                uploadCallback: this.uploadImageCallBack,
                popupClassName: 'channel-rte-popup',
              },
              link: {
                defaultTargetOption: '_blank',
                popupClassName: 'channel-rte-popup',
              },
              emoji: {
                popupClassName: 'channel-rte-popup',
                className: 'rte-emoji'
              },
            }}
          />
          <div className="rich-text-editor-actions text-end">
            <button
              className="cancel-button btn btn-lightgray btn-sm"
              type="reset"
              onClick={() => this.handleOnClear()}
            >
              Cancel
            </button>
            <button
              className="submit-button btn btn-submit btn-sm ms-2"
              type="submit"
              onClick={(e) => this.handleOnSave(event)}
            >
              Submit
            </button>
          </div>
          {/*
          <textarea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          />
          */}
        </div>
      </div>
    );
  }
}

export default withRouter(MessageForm);
