import { connect } from 'react-redux';
import HomeDashboard from './home_dashboard';

import { fetchWorkspaces } from '../../../actions/workspace_actions';
import { createChannel } from '../../../actions/channel_actions';
import { openModal } from '../../../actions/modal_actions';
import {
  isFreeTrialAccount,
  isCurrentUserAccountAdmin,
  isCurrentUserWorkspaceManager,
  isEducationOrganizationPersona,
} from '../../../reducers/selectors';

import { queryParamValue } from '../../../components/util/helpers';

import withRouter from '../../../util/with_router';

// same as connect
const msp = (state, ownProps) => {
  const { entities } = state;
  const { search } = ownProps.location;
  const dashboard = queryParamValue({ search, key: 'dashboard' });

  return {
    currentUser: entities.currentUser,
    isFreeTrialAccount: isFreeTrialAccount({ entities }),
    workspaces: entities.workspaces,
    currentLocale: ownProps.currentLocale,
    lastVisitedChannel: ownProps.lastVisitedChannel,
    lastVisitedWorkspace: ownProps.lastVisitedWorkspace,
    defaultWorkspaceId: entities.organization?.default_workspace_id,
    isCurrentUserAccountAdmin: isCurrentUserAccountAdmin({ entities }),
    isCurrentUserWorkspaceManager: isCurrentUserWorkspaceManager({ entities }),
    isEducationOrganizationPersona: isEducationOrganizationPersona({ entities }),
    dashboardType: dashboard,
  };
};

// same as dispatch
const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    openModal: (modal, props) => dispatch(openModal(modal, props)),
    fetchWorkspaces: () => organizationId && dispatch(fetchWorkspaces({ organizationId })),
    createChannelInActivationWizard: ({ workspaceId, channel }) => dispatch(
      createChannel({ organizationId, workspaceId, channel })
    ),
  };
};

export default withRouter(connect(msp, mdp)(HomeDashboard));
