import { connect } from 'react-redux';

import MessageList from './message_list';
import withRouter from '../../../../util/with_router';
import { deleteMessage, fetchMessages } from '../../../../actions/message_actions';
import { getChannelMessages } from '../../../../reducers/selectors';

const msp = (state, ownProps) => {

  return {
    currentUser: state.entities.currentUser,
    channel: state.entities.channels[ownProps.match.params.channelId],
    getChannelMessages: getChannelMessages(state, ownProps.match.params.channelId),
    workspaceId: ownProps.match.params.workspaceId,
  };
};

const mdp = (dispatch, ownProps) => {
  const workspaceId = ownProps.match.params.workspaceId;

  return {
    fetchMessages: ({ organizationId, channelId, pageSize }) =>
      dispatch(
        fetchMessages({ organizationId, workspaceId, channelId, pageSize }),
      ),
    deleteMessage: ({ organizationId, channelId, messageId }) =>
      dispatch(deleteMessage({ organizationId, workspaceId, channelId, messageId })),
  };
};

export default withRouter(connect(msp, mdp)(MessageList));
