import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }

  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {}
    }
  }

  next = values => {
    const val = values;
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values: val,
    }));
  }


  previous = () => {
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));
  }


  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = values => {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values)
    } else {
      this.next(values)
    }
  }

  render() {
    const {
      children,
      handlePageSkip,
      omitFinalOnSubmit,
    } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1

    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting, values }) => (
          <div className="form-wizard">
            <form onSubmit={handleSubmit}>
              <div className="form-fields-container">
                {activePage}
              </div>
              <div className="buttons align-items-center">
                {page > 0 && (
                  <button type="button" onClick={() => this.previous()}>
                    « Previous
                  </button>
                )}
                {!isLastPage && <button type="submit">Next »</button>}
                {!isLastPage && <div className="skip-page" onClick={() => this.next()}>Skip »</div>}
                {!omitFinalOnSubmit && isLastPage && (
                  <button type="submit" disabled={submitting}>
                    Submit
                  </button>
                )}
              </div>

            </form>
          </div>
        )}
      </Form>
    )
  }
}
