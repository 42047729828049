import React, { Fragment } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import withRouter from '../../../util/with_router';

import RequestsBreakdownCard from '../widgets/RequestsBreakdownCard';
import CustomSummaryCard from '../widgets/CustomSummaryCard';
import PulseOfTheCustomerCard from '../widgets/PulseOfTheCustomerCard';
import OpportunityValueBySizeCard from '../widgets/OpportunityValueBySizeCard';
import AssetChurnRiskAssessmentCard from '../widgets/AssetChurnRiskAssessmentCard';
import UnresolvedRequestsNearRenewal from '../widgets/UnresolvedRequestsNearRenewal';
import RequestsForMyTeamByRenewal from '../widgets/RequestsForMyTeamByRenewal';
import RequestsCreatedOverTime from '../widgets/RequestsCreatedOverTime';
import RequestsStatusSummary from '../widgets/RequestsStatusSummary';
import AtRiskCustomersCard from '../widgets/AtRiskCustomersCard';
import RequestsByCustomerRiskLevelCard from '../widgets/RequestsByCustomerRiskLevelCard';

class PrimaryDashboardWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {

    const {
      currentLocale,
      currentUser,
      isCurrentUserAccountAdmin,
      isEducationOrganizationPersona,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      openModal,
      useDemoData,
      workspaceId,
    } = this.props;

    return (
      <div className="primary-dashboard">
        <Row>
          <Col>
            <RequestsCreatedOverTime
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
          <Col>
            <RequestsStatusSummary
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <AssetChurnRiskAssessmentCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
          <Col sm={4}>
            <RequestsByCustomerRiskLevelCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <OpportunityValueBySizeCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AtRiskCustomersCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
          <Col>
            <PulseOfTheCustomerCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <UnresolvedRequestsNearRenewal
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={`${workspaceId}-months-3`}
              months={3}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <UnresolvedRequestsNearRenewal
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={`${workspaceId}-months-6`}
              months={6}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <CustomSummaryCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
            />
          </Col>
          <Col md={6}>
            <RequestsBreakdownCard
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={workspaceId}
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
              isEducationOrganizationPersona={isEducationOrganizationPersona}
            />
          </Col>
        </Row>
        {/* Only render this widget for delivery team members as this is team oriented */}
        {true && (
          <Row>
            <Col>
              <RequestsForMyTeamByRenewal
                currentLocale={currentLocale}
                workspaceId={workspaceId}
                defaultChannelId={defaultChannelId}
                currentUser={currentUser}
                key={`${workspaceId}-months-9`}
                months={3}
                openModal={openModal}
                handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
                useDemoData={useDemoData}
                isEducationOrganizationPersona={isEducationOrganizationPersona}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withRouter(PrimaryDashboardWidgets);
