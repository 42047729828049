import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class AboutPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.props;
    return (
      <div className="about-page-drawer channels">
        <div className="content-container">
          <div className="header">
            About this page
          </div>
          <div className="content">
            <div className="section">
              <div className="subheader">Purpose:</div>
              <div className="section-content">
                Manage users. The more sales and delivery team members there are
                as users, the more effective responses to customers there will be.
              </div>
            </div>
            <div className="section">
              <div className="subheader">To get started:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Add teammates
                </div>
                <div className="section-list">
                  <ol>
                    <li className="list-item">
                      Add user information
                    </li>
                    <li className="list-item">
                      Add role
                    </li>
                    <li className="list-item">
                      Add user to workspace(s)
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="subheader">Role types and permissions:</div>
              <div className="section-content">
                <div className="underline subsection-header mb-2">
                  Account level roles:
                </div>
                <div className="section-list">
                  <ol>
                    <li>
                      Account admin:
                      <ul>
                        <li className="list-item first-sublist-item">
                          Full access
                        </li>
                        <li className="list-item first-sublist-item">
                          Only other account admins can assign this role
                        </li>
                      </ul>
                    </li>
                    <li>
                      Account user (default role):
                      <ul>
                        <li className="list-item first-sublist-item">
                          Participate in workspaces
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div className="underline subsection-header mb-2">
                  Workspace level roles:
                </div>
                <div className="section-list">
                  <ol>
                    <li>
                      Workspace manager:
                      <ul>
                        <li className="list-item first-sublist-item">
                          manage workspace users
                        </li>
                        <li className="list-item first-sublist-item">
                          manage teams
                        </li>
                        <li className="list-item first-sublist-item">
                          assign users to teams
                        </li>
                        <li className="list-item first-sublist-item">
                          manage Customer Requests
                        </li>
                        <li className="list-item first-sublist-item">
                          comment in Customer Requests
                        </li>
                      </ul>
                    </li>
                    <li>
                      Workspace user:
                      <ul>
                        <li className="list-item first-sublist-item">
                          manage Customer Requests
                        </li>
                        <li className="list-item first-sublist-item">
                          comment in Customer Requests
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutPageDrawer;
