import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { updateOrganizationInvitation } from '../../../services/organization_api';
import { toast } from 'react-toastify';
import { toastifyConfiguration } from '../../../components/util/helpers';
import AboutPageDrawer from '../../AboutDrawers/InvitationsSettingsPage/AboutPageDrawer';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

const tabPath = (locale, status) =>
  status
    ? `/#/${locale}/settings/invitations?status=${status}`
    : `/#/${locale}/settings/invitations`;

const isActiveClass = (paramValue, status) =>
  paramValue?.toLowerCase() === status ? 'active' : '';

const unclickableCellClass = isUnclickable =>
  isUnclickable ? 'unclickable-cell' : '';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      invitations: [],
      invitationsCount: 0,
      isAboutPageDrawerOpen: false,
      approved: undefined,
      revoked: undefined,
    };
    this.handleOnClickAcceptInvitation = this.handleOnClickAcceptInvitation.bind(this);
    this.handleGetPaginatedInvitations = this.handleGetPaginatedInvitations.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmAction = this.handleConfirmAction.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleGetPaginatedInvitations = async (page, pageSize) => {
    const { currentUser, invitationStatus } = this.props;
    const { search } = this.state;
    await this.props.fetchInvitations({
      page: page + 1,
      pageSize: pageSize,
      status: invitationStatus,
      search,
    });
    this.setState({ loading: false });
  }

  handleConfirmAction = async (invitation, acceptStatus, event) => {
    event && event.preventDefault();
    const { approved, revoked, returnVoid } = acceptStatus;
    if (returnVoid) {
      return;
    }
    const action = approved ? 'approve' : 'revoke';
    this.setState({ approved, revoked });

    confirmAlert({
      customUI: ({ onClose }) => {``
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickAcceptInvitation}
            subtitle={`You want to ${action} this invitation?`}
            itemToRemove={invitation}
            confirmButtonText={`Yes, ${action} it!`}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickAcceptInvitation = async (invitation, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const { approved, revoked } = this.state;

    const action = approved ? 'approve' : revoked ? 'revoke' : 'update';

    const response = await updateOrganizationInvitation({
      organizationId: currentUser?.organization_id,
      id: invitation.id,
      approved,
      revoked,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedInvitations(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success(`Invitation ${action}d successfully!`, toastifyConfiguration({}));
    } else {
      toast.error(`Error ${action}ing the invitation. Please try again.`, toastifyConfiguration({}));
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getUsersCsv({ organization_id: currentUser?.organization_id });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  render() {
    let {
      currentUser,
      invitations,
      currentLocale,
      invitationsCount,
      pages,
      invitationStatus,
    } = this.props;

    const columns = [{
      accessor: 'email',
      Header: 'User',
      sortable: false,
    }, {
      accessor: 'inviter_email',
      Header: 'Invited by',
      sortable: false,
    }, {
      id: 'created_at',
      Header: 'Invited on',
      sortable: false,
      accessor: invitation => <span>{moment(invitation.created_at).utc().format('YYYY-MM-DD')}</span>
    },  {
      id: 'accept',
      Header: 'Approve invitation',
      sortable: false,
      accessor: invitation => (
        <div>
          <span
            className={`edit-cell accept-invitation-cell ${unclickableCellClass(!invitation.accepted || invitation.revoked || invitation.approved)}`}
            onClick={this.handleConfirmAction.bind(this, invitation, { approved: true, returnVoid: !invitation.accepted || invitation.approved || invitation.revoked })}
          >
            {invitation.approved
              ? 'Already approved'
              : invitation.accepted
              ? 'Approve User'
              : invitation.revoked
              ? 'Already revoked'
              : invitation.accepted === 'false'
              ? 'User declined'
              : 'Pending user acceptance'}
          </span>
        </div>
      )
    }, {
      id: 'approved_by',
      accessor: invitation => invitation.approved_by || 'N/A',
      Header: 'Approved by',
      sortable: false,
    }, {
      id: 'reject',
      Header: 'Revoke invitation',
      sortable: false,
      accessor: invitation => (
        <div>
          <span
            className={`remove-cell ${unclickableCellClass(invitation.approved || invitation.revoked)}`}
            onClick={this.handleConfirmAction.bind(this, invitation, { revoked: true, returnVoid: invitation.approved || invitation.revoked })}
          >
            {invitation.approved
              ? 'User already approved'
              : invitation.revoked
              ? 'Already revoked'
              : 'Revoke'}
          </span>
        </div>
      )
    }, {
      id: 'revoked_by',
      accessor: invitation => invitation.revoked_by || 'N/A',
      Header: 'Revoked by',
      sortable: false,
    }]

    return (
      <div className="settings organization-users user-invitations container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Access</div>
          <div className="subheader">
            <div className="page">Invitations</div>
            <div className="hyphen">-</div>
            <div className="small-title">Setting</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="summary">
            <div className="summary-actions">
              <div className="count">
                <div>Total Invitations</div>
                <div className="count-container">{invitationsCount}</div>
              </div>
            </div>
          </div>
          <ul className="nav nav-tabs user-invitation-tabs mt-4">
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus)}`} aria-current="page" href={tabPath(currentLocale)}>
                All
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'pending')}`} aria-current="page" href={tabPath(currentLocale, 'pending')}>
                Pending
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'accepted')}`} href={tabPath(currentLocale, 'accepted')}>
                Awaiting your final approval
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'rejected')}`} href={tabPath(currentLocale, 'rejected')}>
                Rejected by invitee
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'approved')}`} href={tabPath(currentLocale, 'approved')}>
                Approved
              </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${isActiveClass(invitationStatus, 'revoked')}`} href={tabPath(currentLocale, 'revoked')}>
                Revoked
              </a>
            </li>
          </ul>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={invitations}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              loading={this.state.loading}
              pages={pages}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                this.setState({ loading: true });
                this.handleGetPaginatedInvitations(state.page, state.pageSize);
              }}
            />
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings users-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
