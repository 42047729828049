import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Select from 'react-select'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getReactFinalFormSelectValues,
  sleep,
  toastifyConfiguration,
} from '../../components/util/helpers';
import { updateWorkspaceMember } from '../../services/workspace';

toast.configure();

class EditWorkspaceMemberPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnSubmit = async values => {
    await sleep(300);
    const { currentUser, workspaceId, selectedWorkspaceMember, onEditWorkspaceMemberPaneClose } = this.props;
    const { roles } = values;
    const workspaceMember = await updateWorkspaceMember({
        organization_id: currentUser?.organization_id,
        workspace_id: workspaceId,
        workspace_member_id: selectedWorkspaceMember.id,
        roles: roles ? getReactFinalFormSelectValues(roles) : [],
      });

    if (!(workspaceMember || {}).error) {
      onEditWorkspaceMemberPaneClose();
      location.reload();
    } else {
      toast.error(`Error updating workspace member, ${selectedWorkspaceMember.email}. Please try again.`, toastifyConfiguration({}));
    }
  }

  render() {
    const { permittedWorkspaceRoles, selectedWorkspaceMember } = this.props;
    const initialRolesValue = (selectedWorkspaceMember || []).roles.map(role => ({
      value: role.name, label: ((permittedWorkspaceRoles || []).filter(workspaceRole => workspaceRole.value === role.name).shift() || {}).label
    }));

    return (
      <div className="add-user-pane">
        <Form
          onSubmit={this.handleOnSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                initialValue={selectedWorkspaceMember.email}
              >
                {({ input, meta }) => (
                  <p className="field email">
                    <input {...input} type="text" disabled />
                  </p>
                )}
              </Field>
              <Field
                name="roles"
                validate={requiredSelect}
                initialValue={initialRolesValue}
                component={ReactSelectAdapter}
                options={(permittedWorkspaceRoles || []).map(role => ({
                  value: role.value, label: role.label
                }))}
              />
              <div className="submit">
                <input type="submit" name="commit" value="Submit" disabled={submitting} />
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

EditWorkspaceMemberPane.propTypes = {
};

export default EditWorkspaceMemberPane;

// private

const ReactSelectAdapter = ({ input, meta, ...rest }) => (
  <div className="field select">
    <Select {...input} {...rest} searchable isMulti />
    {meta.error && meta.touched && <span className="field-error">{meta.error}</span>}
  </div>
)

const requiredSelect = value => value && !!value.length ? undefined : 'Required';
