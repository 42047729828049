import { connect } from 'react-redux';
import Content from './content';
import withRouter from '../../../util/with_router';
import { fetchOrganizationRequests } from '../../../actions/organization_actions';

const msp = (state, ownProps) => {
  const { entities } = state;

  return {
    currentUser: entities.users[state.session.id],
    requests: entities.organization.requests,
    pages: entities.organization.pages || 1,
    requestsCount: entities.organization.requestsCount || 0,
    workspaces: entities.workspaces,
  };
};

const mdp = (dispatch, ownProps) => {
  const { currentUser } = ownProps;
  const organizationId = currentUser?.organization_id;

  return {
    fetchRequests: ({ filters, page, pageSize, search }) =>
      dispatch(fetchOrganizationRequests({ organizationId, filters, page, pageSize, search })
    ),
  };
};

export default withRouter(connect(msp, mdp)(Content));
