import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

class NoDataAvailableWidgetState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { currentLocale } = this.props;

    return (
      <Fragment>
        <div className="no-data-available">
          See what this widget will look like once there's more data.
        </div>
        <div className="no-data-available continuation">
          <a
            href={`/#/${currentLocale}/dashboard-onboarding-demo`}
            target="_blank"
          >
            View demo dashboard
          </a>
        </div>
      </Fragment>
    );
  }
}

NoDataAvailableWidgetState.propTypes = {};

export default NoDataAvailableWidgetState;
