import { paramsToQueryString } from '../services/util/helpers';

export async function fetchWorkspaceOpportunities({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/opportunities.json`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceSummaryCounts({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/summary_counts.json`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceTaggedCounts({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/tagged_counts.json`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceAssetsTaggedCounts({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/asset_tagged_counts.json`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceCustomerPulse({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/pulses.json`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceRequestsNearRenewals({
  workspaceId,
  organizationId,
  months,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/requests_near_renewals?months=${months}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceRequestStatuses({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/request_statuses`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceRequestCreations({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/request_creations`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceAtRiskCustomerRequests({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/at_risk_customer_requests`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceTeamRequestsNearRenewal({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/team_requests_near_renewals`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceRequestsStatusByDates({
  workspaceId,
  organizationId,
  periodRange,
  status,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/requests_status_by_dates?period_range=${periodRange}&status=${status}`;

  return $.ajax({
    url: url,
  });
}

export async function fetchWorkspaceRequestsByCustomerRiskLevels({
  workspaceId,
  organizationId,
}) {

  const url =
    `/organizations/${organizationId}/stats/${workspaceId}/requests_by_customer_risk_levels`;

  return $.ajax({
    url: url,
  });
}
